wc-camera-section {
  .mat-tab-group.camera-tab-group {
    flex-direction: column;
    min-height: unset;

    .mat-tab-body-wrapper {
      background-color: unset;
    }

    .mat-tab-header {
      width: 100%;
      padding: 0 14px;
      border-bottom: none;
      background-color: unset;
      box-shadow: unset;

      .tablet-mode & {
        padding: 0 28px;
      }
    }

    .mat-tab-label-container {
      border-bottom: 1px solid var(--wc-grey-4-to-white-alpha-2);
    }

    .mat-tab-label-content {
      color: var(--wc-light-blue-8-to-light-blue-5);

      .tablet-mode & {
        font-size: 22px;
      }
    }

    .mat-tab-labels {
      flex-direction: row;
      padding: 0;
      border-top: none;

      .tablet-mode & {
        width: 350px;
      }
    }

    .mat-tab-label {
      width: unset;
      min-width: 25px !important;
      height: 58px;
      padding: 5px 8px;

      .widget & {
        height: 37px;
      }

      &:not(.mat-tab-label-active) {
        font-weight: 400;
      }

      &.mat-tab-label-active {
        border-bottom: 3px solid var(--wc-light-blue-8-to-light-blue-5);
        border-left: unset;
        background-color: unset;
      }

      &.mat-tab-disabled {
        position: unset;
        right: unset;

        .mat-tab-label-content {
          color: var(--wc-deep-blue-1-to-greyscale-4);
        }
      }
    }

    &.mat-primary .mat-ink-bar {
      width: unset !important;
      background-color: transparent;
    }

    .mat-tab-body-content {
      padding: 14px;

      .tablet-mode & {
        padding: 14px 28px !important;
      }
    }
  }
}
