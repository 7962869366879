.ol-zoom .ol-zoom-in {
  @include rounded-corner(6px 6px 0 0);

  width: 100%;
  height: 50%;
  margin: 0;
}

.ol-attribution {
  right: unset;

  &:not(.ol-collapsed) {
    background-color: unset;
  }

  &.ol-uncollapsible {
    right: unset;
  }
}

.ol-zoom .ol-zoom-in::before {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 60%;
  border-bottom: 1px solid #e0e0e0;
  transform: translate(-50%, 1px);
}

.ol-zoom .ol-zoom-out {
  @include rounded-corner(0 0 6px 6px);

  width: 100%;
  height: 50%;
  margin: 0;
}

.ol-zoom-in,
.ol-zoom-out {
  position: relative;
  color: $deep-blue-6;

  &:hover {
    color: $light-blue-8;
  }

  &[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: 2px;
    left: -60px;
    z-index: 10;
    width: max-content;
    margin-top: 5px;
    padding: 0 6px;
    border-radius: 2px;
    background: $deep-blue-6;
    color: $white;
    box-shadow: 0 0 5px rgba(15, 24, 50, 40%);
    font-size: 12px;
    font-family: $primary-font-family;
    line-height: 22px;
    opacity: 0.9;
  }
}

.ol-zoom-out {
  box-shadow: 0 5px 8px 0 rgb(15 24 50 / 25%);

  &[title]:hover::after {
    left: -68px;
  }
}

.ol-zoom-in {
  box-shadow: 0 -3px 20px 0 rgb(15 24 50 / 25%);
}

.ol-control button {
  @include rounded-corner(2px);

  display: block;
  width: 1.375em;
  height: 1.375em;
  margin: 1px;
  padding: 0;
  border: none;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  color: var(--wc-greyish-blue-5-to-white-alpha-7);
  font-weight: 700;
  font-size: 19px;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
}

.ol-control {
  @include rounded-corner(4px);

  position: absolute;
  padding: 2px;
  background-color: unset;
}

@media (hover: hover) {
  .ol-control button:hover {
    background-color: var(--wc-grey-2-to-dark-greyish-blue-3);
    text-decoration: none;
    cursor: pointer;

    .widget & {
      background-color: $light-blue-2;
    }
  }
}

.ol-control button:active {
  background-color: var(--wc-grey-3-to-dark-greyish-blue-2);
  text-decoration: none;
}

.ol-zoom.ol-unselectable.ol-control {
  @include rounded-corner(6px);

  top: auto;
  right: 10px;
  bottom: 26px;
  left: auto;
  width: 59px;
  height: 93px;
  padding: 11px;
  background: var(--wc-white-alpha-4-to-transparent);
  box-shadow: 4px 4px 19px rgba($deep-blue-6, 15%);

  .widget & {
    bottom: 12px;
    width: 47px;
    height: 78px;
    padding: 7px;
  }
}

@media (hover: hover) {
  .map-center-btn:hover {
    background-color: var(--wc-grey-2-to-white-8);
  }

  .map-center-btn:focus {
    background-color: var(--wc-grey-2-to-white-7);
  }
}

.map-center-btn:active {
  background-color: var(--wc-greyscale-2-to-grey-2);
}

.map-center-btn {
  @include rounded-corner(8px);

  position: absolute;
  bottom: 30px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: var(--wc-white-to-white-6);
  box-shadow: 0 0 5px rgb(15 24 50 / 40%);
  cursor: pointer;
}
