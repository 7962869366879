.mat-tooltip {
  &.edit-block-tooltip {
    margin: -30px -500px -50px 0 !important;
    padding: 5px;
    background-color: $yellow-0;
    color: var(--wc-deep-blue-6-to-greyscale-3) !important;
    box-shadow: 0 0 5px rgba($deep-blue-6, 40%);
    font-size: 12px;
    font-family: $primary-font-family;
  }

  &.reported-some-time-ago-tooltip {
    max-width: 207px;
    margin-bottom: 0;
    padding: 6px;
    background-color: $deep-blue-6;
    color: $white;
    font-size: 12px;
    font-family: $primary-font-family;
    line-height: 14px;
  }
}
