wc-autocomplete {
  .mat-form-field-flex {
    transition: unset;
  }

  .autocomplete-panel-opened {
    .mat-form-field-outline {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .mat-form-field-outline {
    border-radius: 5px;
    background-color: $white;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 !important;
  }

  .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    opacity: 1;
  }
}
