// See response-plan-form/response-plan-form-action/response-plan-form-action.component.html and response-plan-form/response-plan-form.component.html
.response-plan-form {
  height: 100%;
  font-family: $primary-font-family;

  &__table-container {
    overflow: auto;
    height: 100%;
  }

  &__action-list-container {
    position: relative;
    display: grid;
    grid-template-columns: 50px 1fr 1fr 2fr 2fr;
    gap: 30px;
    align-items: end;
    width: fit-content;

    &.highlighted-action {
      background-color: $grey-2;
    }
  }

  &__edit-icon-container {
    position: absolute;
    top: 10px;
    right: 0;
  }

  &__edit-icon {
    display: none;
    padding: 25px 13px;
    cursor: pointer;
  }

  &__action-pop-up {
    position: absolute;
    top: 12px;
    right: 31px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 190px;
    padding: 8px 0 8px 12px;
    background: var(--wc-white-to-white-alpha-1-half);
    color: var(--wc-greyish-blue-5-to-white-alpha-7);
    box-shadow: 0 0 9px rgba($deep-blue-6, 14%);
  }

  &__popup-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding-right: 20px;

    &:hover {
      color: $deep-blue-6;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
      }
    }

    &--disabled {
      color: $disabled;
      pointer-events: none;
    }
  }

  .response-plan-form__action-list-container:hover .response-plan-form__edit-icon {
    display: block;
  }

  ///////////////////////////////////////////////

  //UI mode 1
  .response-plan-form--template-edit & {
    &__action-list-container {
      align-content: center;
      width: 100%; // need to verify
      height: 70px;
      padding: 0 35px 0 60px;
    }

    &__column-names {
      display: grid;
      grid-template-columns: 50px 1fr 1fr 2fr 2fr;
      gap: 30px;
      padding: 0 35px 10px 60px;
      color: $dark-grey-4;

      span:not(:first-child) {
        min-width: 180px;
      }
    }
  }

  //UI mode 2
  .response-plan-form--entity-edit & {
    &__action-list-container {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(5, minmax(0, 1fr));
      row-gap: 10px;
      margin-bottom: 15px;
      padding: 5px 25px;
      border: 1px solid $disabled;
      border-radius: 5px;
      column-gap: 16px;

      &.invalid-panel {
        border-color: red;
      }

      &.highlighted-action {
        &:not(.invalid-panel) {
          border-color: $deep-blue-1;
        }
      }
    }

    &__item-field {
      &--time {
        grid-area: 1 / 1 / 2 / 2;
        max-width: 65px;
        text-align: center;
      }

      &--time-unit {
        grid-area: 1 / 2 / 2 / 4;
      }

      &--time-badge {
        grid-area: 1 / 4 / 2 / 6;
        align-self: center;
        padding: 9px;
        border-radius: 4px;
        background-color: $greyscale-1;
        color: $greyscale-5;
        font-size: 13px;
      }

      &--actionField {
        grid-area: 2 / 1 / 3 / 3;
      }

      &--action-values {
        grid-area: 2 / 3 / 3 / 6;
      }

      &--free-text {
        grid-area: 3 / 1 / 4 / 6;
      }
    }

    &__column-names {
      display: none;
    }
  }
}

.account-response-plan-list-component {
  .settings-input-field {
    .mat-form-field-infix {
      padding-right: 24px;
    }
  }
}
