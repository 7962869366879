.view-traffic-disruption {
  .mat-tab-header {
    background-color: var(--wc-white-to-dark-greyish-blue-9);
  }

  .mat-tab-group,
  .mat-tab-label-container {
    z-index: 0;
  }

  .mat-tab-labels {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 58px;
    padding: 0 10px;
    border-top: 1px solid var(--wc-disabled-to-white-alpha-2);
  }

  .mat-tab-label {
    min-width: 25px;
    height: 58px;
    padding: 5px 8px;
    border-bottom: 3px solid transparent;
    color: $greyscale-5;
    font-size: 14px;
    font-family: $primary-font-family;
    opacity: 1;
  }

  .mat-tab-label-active {
    border-bottom: 3px solid $light-blue-8;
    color: $light-blue-8;
    font-weight: 600;
  }

  .mat-ink-bar {
    background-color: transparent !important;
  }

  .mat-tab-body-content {
    padding: 12px;
  }

  .media-tab-group {
    margin-right: 8px;

    .mat-tab-labels {
      padding: 0;
      border: none;
    }

    .mat-tab-label {
      color: $deep-blue-6;
    }

    .mat-tab-body-content {
      height: 280px;
      padding: 12px 0;
      background-color: $white;
    }

    .media-gallery-component__media-list {
      padding: 0;
    }

    .media-gallery-component__show-all {
      margin-top: 10px;
    }

    &.tab-long-content {
      .mat-tab-body-content {
        height: 380px;
      }
    }
  }
}
