.mat-tooltip.updated-time-ago-tooltip {
  background-color: $deep-blue-6;
  color: $white;
  margin-bottom: 0px;
  max-width: 250px;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: pre-line;
  font-family: $primary-font-family;
  line-height: 16px;
}
