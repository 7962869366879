@import '~swiper/swiper-bundle.min.css';

.swiper-slide {
  color: var(--wc-black-alpha-8-to-white-alpha-8);
}

.swiper-button-next.camera-swiper-button-next,
.swiper-button-prev.camera-swiper-button-prev {
  &::after {
    background-color: var(--wc-white-to-white-4) !important;
    color: var(--wc-deep-blue-6-to-white-1) !important;
  }
}

.swiper-pagination-bullet {
  background-color: var(--wc-grey-4-to-white-alpha-5);
  opacity: 1;

  .tablet-mode & {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: var(--wc-grey-6-to-white-alpha-7);
  }
}

.swiper-pagination-bullet-active {
  background-color: var(--wc-greyish-blue-5-to-white-alpha-9);
}
