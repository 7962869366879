@import '~@angular/material/theming';
@import './colors';

/* Fonts */
$primary-font-family: 'Nunito Regular', 'Rubik', sans-serif;
$primary-font-family-bold: 'Nunito Bold', 'Rubik Bold', sans-serif;
$primary-font-family-semi-bold: 'Nunito SemiBold', 'Rubik Bold', sans-serif;
$primary-font-family-light: 'Lato Light';

/* Material Theme Colors */
$primary: mat-palette($mat-blue, 600);
$accent: mat-palette($mat-indigo, 500);
$warn: mat-palette($mat-pink, 500);
$theme: mat-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include angular-material-theme($theme);

/* Extra Variable */
$rgt: right;
$lft: left;
$radius: 4px;

/** WC2 variables */
// Fonts
$fontawesome-icons: 'Font Awesome 5 Pro';
$waycare-font-icons: 'Waycare Icons';
$font-size-desktop-small: 12px;
$font-size-desktop-large: 18px;
$base-font-size-desktop: 14px;
$medium-font-size: 16px;
$validation-error-font-size: 12px;
$tablet-small-text: 20px;
$base-font-size-tablet: 24px;
$tablet-medium-text: 26px;
$tablet-large-text: 28px;
$widget-base-font-size: 13px;

// Sizes
$incidents-list-wrapper-padding: 5px;
$incident-list-tabs-height: 65px;
$incident-list-sub-tabs-height: 75px;
$incident-list-tabs-height-tablet: 65px;
$main-nav-header-height: 60px;
$main-nav-header-height-work-request: 250px;
$second-toolbar-height: 90px;
$incident-list-sort-bar-height: 68px;
$incident-list-bottom-padding: 20px;
$map-layers-panel-button: 60px;
$map-data-layers-buttons-height: 60px;
$view-incident-header-height: 160px;
$incident-form-title-height: 52px;
$tablet-incident-form-title-height: 90px;
$road-closure-header-height: 66px;
$analytics-log-title-height: 70px;
$table-header-height: 70px;
$analytics-table-header-height: 22px;
$analytics-table-footer-height: 50px;
$view-details-panel-header-height: 60px;
$tablet-stepper-navigation-panel-width-sm: 105px;
$tablet-stepper-navigation-panel-width-md: 130px;
$desktop-panel-width: 562px;
$side-panel-top-margin: 10px;
$side-panel-bottom-margin: 26px;
$side-panel-calc-height: calc(
  100vh - #{$main-nav-header-height} - #{$side-panel-top-margin} - #{$side-panel-bottom-margin}
);
$side-panel-calc-portrait-mode-height: calc(
  50vh - #{$main-nav-header-height} - #{$side-panel-top-margin} - #{$side-panel-bottom-margin}
);
$base-icon-size: 20px;

//$view-details-header-height: 290px;
$view-details-header-height: 120px;
$view-details-tabs-height: 58px;
$tablet-form-buttons-container: 90px;

// Incident View
$side-panel-width: 562px;
$incident-title-height: 56px;
$incident-stepper-height: 86px;
$incident-footer-height: 70px;
$incident-form-footer-tablet: 94px;
$additional-actions-footer-height: 104px;

/* Borders */
$thin-divider-border: 0.5px solid rgba(151, 151, 151, 50%);

/* Media Query Breakpoints */
$breakpoint-xs: 320px;
$breakpoint-sm: 576px; // 712px;
$breakpoint-md: 768px; // 768px;
$breakpoint-lg: 992px; // 1024px;
$breakpoint-xl: 1200px; // 1366px;
$breakpoint-xxl: 1366px; // 1366px and higher;
$breakpoint-fhd: 1920px;
$breakpoint-uhd: 3840px;

/* Grid width by media queries */

/* @link https://ionicframework.com/docs/layout/grid#grid-size */
$grid-width-xs: 100%;
$grid-width-sm: 540px; // 576px;
$grid-width-md: 720px; // 768px;
$grid-width-lg: 960px; // 992px;
$grid-width-xl: 1140px; // 1200px;

/* Transition constnants */
$background-color-transition: background-color ease 280ms;
