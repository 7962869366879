.train-delays {
  &.mat-expansion-panel {
    border-radius: 0;

    .mat-expansion-panel-body {
      padding: 0 14px;
    }
  }

  &.empty .mat-expansion-indicator::after {
    border-color: rgba($deep-blue-6, 0.5) !important;
  }

  .mat-expansion-panel-header {
    background-color: $red-0 !important;
    line-height: 46px;
  }

  .mat-expansion-panel-body {
    padding: 0 14px 16px;
    background-color: $red-0 !important;
  }

  .mat-expansion-indicator {
    position: absolute;
    top: unset !important;
    right: unset !important;
    height: 50px;
    color: $red-5 !important;
  }

  .mat-expansion-panel-header-title {
    margin-left: 20px;
    background-color: $red-0 !important;
    font-family: $primary-font-family-bold;
  }
}
