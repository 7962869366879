.cctv-snapshots-full-screen {
  .swiper-slide {
    &.active {
      img,
      video,
      .doc-file-thumbnail {
        border: 5px solid var(--wc-light-blue-5-to-light-blue-3-6);
      }
    }

    &:not(.active) {
      opacity: 0.8;
    }
  }
}
