$group-label-height: 25px;

.autocomplete-component {
  &:hover {
    .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
      color: $red-6;
    }
  }

  &.disabled {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      background-color: $grey-1;
      color: $grey-4 !important;
    }

    .mat-autocomplete-trigger {
      color: $grey-6;
      opacity: 1;
    }

    &.multi .mat-autocomplete-trigger {
      opacity: 0;
    }

    .mat-icon-button {
      color: $grey-6;
    }
  }

  &__input {
    height: 100%;
    line-height: 14px !important;
  }

  &__option-group {
    .mat-optgroup-label {
      height: $group-label-height;
      background-color: $greyscale-1;
      color: $deep-blue-3;
      font-size: 12px;
      line-height: $group-label-height;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &__option {
    &--text {
      .multi & {
        padding: 0 12px 0 8px;
      }
    }
  }

  &__option.mat-option {
    height: auto;
    min-height: 36px;
    padding: 0 !important;
    font-family: $primary-font-family;
    line-height: unset;

    &.load-more {
      padding: 0 !important;
    }

    &.mat-option-disabled {
      background-color: $white;
      font-size: 14px;
      text-align: center;
    }

    &:not(.mat-active):not(.mat-option-disabled):hover {
      background-color: unset !important;

      &:not(.mat-selected) {
        color: unset !important;
      }
    }

    &.mat-active:not(.mat-option-disabled),
    &.mat-active:not(.mat-option-disabled):hover,
    &:focus:not(.mat-option-disabled) {
      background-color: $light-blue-2 !important;
      color: $light-blue-8 !important;

      .mat-checkbox-frame {
        border-color: $light-blue-8 !important;
      }
    }

    &.mat-selected:not(.mat-active):not(.mat-option-disabled):not(:hover) {
      background-color: unset !important;
      color: $light-blue-8 !important;
    }
  }

  &__checkbox.mat-checkbox {
    display: flex;
    pointer-events: none;

    &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: var(--wc-light-blue-6-to-light-blue-3-8);
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-subscript-wrapper {
      margin-top: 4px;
      padding-left: 0;
    }

    .mat-form-field-flex {
      align-items: center;
      height: 33px;
      margin-top: 0;
      line-height: 33px;
    }

    .mat-form-field-outline {
      top: 0;
      height: 33px;
      line-height: 33px;
    }

    .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 1.34375em;
    }

    .mat-form-field-infix {
      display: flex;
      align-items: flex-end;
      height: 100%;
      border-top-width: 0;
    }

    .mat-form-field-outline {
      color: $grey-4;
    }
  }

  .mat-form-field-appearance-outline:not(.mat-focused):hover .mat-form-field-outline-thick {
    color: $greyish-blue-5;
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $light-blue-8;
  }

  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $red-3;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;

    .mat-icon-button {
      width: 14px;
      height: 14px;

      .mat-icon {
        width: 14px;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
