.incident-component {
  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle-checked {
    border-left: 1px solid $light-blue-6;
  }
  .mat-tab-group,
  .mat-tab-label-container {
    z-index: 0;
  }
  .mat-tab-labels {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 58px;
    padding: 0 10px;
    background-color: $white;
  }

  .mat-tab-label {
    min-width: 25px !important;
    height: 58px !important;
    padding: 5px 8px;
    border-bottom: 3px solid transparent;
    color: $deep-blue-6;
    font-size: 14px;
    font-family: $primary-font-family;
    opacity: 1;
  }

  .mat-tab-label-active {
    border-bottom: 3px solid $light-blue-6;
    color: $light-blue-6;
    font-family: $primary-font-family-bold;
  }

  .mat-ink-bar {
    background-color: transparent !important;
  }

  .mat-tab-body-content {
    padding: 12px;
  }

  .media-tab-group {
    .mat-tab-labels {
      padding: 0;
    }

    .mat-tab-body-content {
      height: 270px;
      padding: 16px 8px 0 0;
    }

    &.tab-long-content {
      .mat-tab-body-content {
        height: 508px;
      }
    }
  }
}
