/** Global Angular Material styles for WC2 */

// Desktop mode
.mat-option.mat-active:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: var(--wc-light-blue-1-to-dark-greyish-blue-10) !important;
  color: var(--wc-light-blue-6-to-light-blue-2-8);
}

.mat-option {
  &.mat-active,
  &.mat-selected {
    .mat-select-panel:not(.mat-primary) & {
      background: var(--wc-white-to-dark-greyish-blue-9) !important;
      color: var(--wc-light-blue-6-to-light-blue-2-8);

      &:hover {
        background: var(--wc-grey-2-to-dark-greyish-blue-10) !important;
      }
    }
  }
}

// Tablet mode
.tablet-mode {
  // Select placeholder
  .mat-form-field-label-wrapper {
    .field-label {
      font-size: $base-font-size-tablet;
    }
  }
}

.mat-checkbox,
.mat-button,
.mat-button:hover,
.mat-button:focus {
  .mat-button-ripple.mat-ripple,
  .mat-button-focus-overlay {
    background: none;
  }
}

.mat-accordion {
  .mat-expansion-panel:first-of-type {
    border: none;
  }
}

.mat-select-trigger {
  height: 17px;
}

/*******************
Forms
*******************/
.form-control {
  width: 100%;
  padding: 10px;
  border: 0;
  line-height: 25px;
}

.mat-form-field-underline {
  background-color: $black-alpha-3;
}

.mat-form-field {
  width: 100%;
}

/* Material overrides for our components */
@import './live-map/live-map';
@import './libs/libs';

// Theme colorization for material components
.mat-dialog-container {
  background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
  color: var(--wc-deep-blue-6-to-greyscale-3) !important;
}

.panel-header.mat-expanded:hover {
  background-color: var(--wc-black-to-white-alpha-7) !important;
  color: var(--wc-deep-blue-6-to-greyscale-3) !important;
}

.mat-select.mat-select-disabled {
  border-bottom-color: var(--wc-disabled-to-white-alpha-2) !important;
}

.mat-select-value {
  padding-left: 2px;
}

.mat-select-value,
.mat-select-value-text {
  color: var(--wc-initial-to-greyscale-3) !important;

  .mat-select-disabled & {
    color: var(--wc-disabled-to-white-alpha-2) !important;
  }
}

.mat-select-arrow {
  color: var(--wc-greyscale-5-to-greyscale-3) !important;

  .mat-select-disabled & {
    color: var(--wc-disabled-to-white-alpha-2) !important;
  }
}

.mat-focused .mat-select-arrow {
  color: #1e88e5 !important; // material color original style
}

.mat-select-panel,
.mat-autocomplete-panel:not(.wc-autocomplete-panel) {
  background-color: var(--wc-white-to-dark-greyish-6) !important;
  box-shadow: var(--wc-box-shadow-mat-select-panel) !important;

  .mat-option:not(.mat-option-disabled):not(.mat-selected):not(.multi-select-option) {
    background-color: var(--wc-white-to-dark-greyish-blue-9);
    color: var(--wc-deep-blue-6-to-greyscale-3);

    @media (hover: hover) {
      &:hover {
        color: var(--wc-light-blue-6-to-light-blue-2-8);
      }
    }
  }

  .mat-option.mat-option-disabled,
  .mat-option.mat-option-disabled:hover {
    color: var(--wc-disabled-to-white-alpha-2) !important;
  }

  .mat-option.mat-active:not(.multi-select-option),
  .mat-option.mat-selected:not(.multi-select-option) {
    .mat-primary & {
      background-color: var(--wc-light-blue-6-to-light-blue-3-8) !important;
      color: var(--wc-white-alpha-8-to-black-alpha-8) !important;

      &:hover {
        color: var(--wc-white-alpha-9-to-black-alpha-9) !important;
      }
    }
  }

  :not(.mat-primary) .mat-option:not(.mat-option-disabled):not(.multi-select-option):hover {
    color: var(--wc-light-blue-6-to-light-blue-2-8) !important;

    :not(.mat-primary) &:not(.mat-active) {
      &:hover {
        color: var(--wc-light-blue-6-to-light-blue-2) !important;
      }
    }
  }

  .multi-select-option:not(.mat-option-disabled):hover {
    .mat-pseudo-checkbox {
      color: var(--wc-light-blue-6-to-light-blue-3-8); // this changes the pseudo-checkbox's border color
    }
  }
}

.mat-primary .mat-option-multiple.mat-active:not(.mat-selected):not(.mat-option-disabled) {
  background-color: transparent !important;

  @media (hover: hover) {
    &:hover {
      background-color: var(--wc-grey-2-to-dark-greyish-blue-10) !important;
    }
  }
}

.mat-primary .multi-select-option.mat-selected:not(.mat-option-disabled) {
  color: var(--wc-white-alpha-8-to-black-alpha-8) !important;

  &:hover {
    color: var(--wc-white-alpha-9-to-black-alpha-9) !important;
  }
}

.mat-expansion-panel,
.mat-expansion-panel-header,
.mat-expansion-panel-header-title {
  background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
  font-family: $primary-font-family;
}

.mat-expansion-panel-header-title {
  .widget & {
    color: $deep-blue-3 !important;
    font-size: 12px;
    font-family: $primary-font-family-bold;
  }
}

.mat-expansion-panel-header {
  height: 44px !important;

  &:hover {
    background: unset !important;
  }
}

.mat-expansion-panel-body {
  font-family: $primary-font-family !important;

  .widget & {
    padding: 0;
  }
}

.mat-select-panel:not(.transit-autocomplete-select-panel):not(.autocomplete-select-panel):not(.select-panel).mat-primary
  .mat-option:not(.mat-option-disabled):not(.multi-select-option) {
  &.mat-selected {
    background-color: var(--wc-light-blue-6-to-light-blue-3-8) !important;
    color: var(--wc-white-alpha-8-to-black-alpha-8) !important;

    &:hover {
      color: var(--wc-white-alpha-9-to-black-alpha-9) !important;
    }
  }
}

.mat-divider {
  border-color: var(--wc-greyscale-2-to-white-alpha-2);
}

.mat-form-field {
  .mat-form-field-label {
    &:not(.mat-empty) {
      color: var(--wc-black-alpha-7-to-white-alpha-7) !important;
    }
  }

  &.mat-focused .mat-form-field-label {
    color: var(--wc-light-blue-6-to-light-blue-2-8) !important;
  }

  &:not(.mat-form-field-invalid):not(.mat-focused) .mat-form-field-label.mat-empty {
    color: var(--wc-black-alpha-5-to-white-alpha-5) !important;
  }
}

.mat-form-field-disabled {
  .mat-select-value,
  .mat-select-value-text,
  .mat-select-arrow,
  mat-label.field-label {
    color: var(--wc-disabled-to-white-alpha-2) !important;
  }

  .mat-form-field-underline {
    background-color: var(--wc-disabled-to-white-alpha-2) !important;
    background-image: none !important;
  }
}

.mat-form-field-underline {
  background-color: var(--wc-greyscale-5-to-greyscale-3) !important;
}

.mat-tab-label-active {
  border-left-color: var(--wc-light-blue-6-to-light-blue-3-8) !important;
  color: var(--wc-light-blue-6-to-light-blue-2-8) !important;
}

.mat-menu-panel {
  background-color: var(--wc-white-to-dark-greyish-6);
}

.mat-drawer {
  background-color: var(--wc-grey-1-to-dark-greyish-blue-9) !important;
}

.mat-flat-button {
  background-color: transparent !important;
}

.mat-checkbox-frame {
  border-color: $grey-6;
}

.mat-checkbox {
  .mat-checkbox-checkmark-path {
    stroke: var(--wc-white-to-black-alpha-7) !important;
  }

  &:hover {
    .mat-checkbox-frame {
      border-color: var(--wc-light-blue-6-to-light-blue-2-8) !important;
    }
  }
}

.mat-accent.mat-checkbox-disabled {
  .mat-checkbox-frame {
    border-color: $grey-6 !important;
  }
}

.mat-pseudo-checkbox {
  &::after {
    color: var(--wc-white-to-black-alpha-7) !important;
  }
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.dark-theme {
  .mat-slide-toggle:not(.mat-checked) {
    .mat-slide-toggle-bar {
      background-color: $greyscale-5 !important;
    }
  }
}

.mat-slide-toggle {
  // width: 100%;
  .mat-slide-toggle-label {
    display: flex;
    justify-content: space-between;
  }

  .mat-slide-toggle-content {
    color: var(--wc-deep-blue-6-to-greyscale-3);
    font-family: $primary-font-family;
  }

  .mat-slide-toggle-bar {
    @include rounded-corner(10px);

    width: 31px;
    height: 20px;
  }

  .mat-slide-toggle-thumb {
    width: 14px;
    height: 14px;
  }

  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    transform: translate3d(1px, 0, 0);
  }

  .mat-slide-toggle-ripple {
    display: none;
  }

  &.mat-checked {
    &:not(.mat-disabled) {
      .mat-slide-toggle-thumb {
        background-color: var(--wc-white-to-dark-greyish-blue-9);
      }

      .mat-slide-toggle-bar {
        background-color: var(--wc-light-blue-6-to-light-blue-3-8);

        &:hover {
          background-color: #0195c9;
        }
      }
    }

    .mat-slide-toggle-thumb {
      background-color: var(--wc-white-to-dark-greyish-blue-9);
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(10px, 0, 0) !important;
    }

    &.mat-disabled {
      .mat-slide-toggle-thumb {
        background-color: var(--wc-white-to-dark-greyish-blue-9);
      }

      .mat-slide-toggle-bar {
        background-color: var(--wc-light-blue-6-to-light-blue-3-8);
      }
    }
  }

  .mat-slide-toggle-label-before .mat-slide-toggle-bar {
    &:hover {
      background-color: $greyscale-6;
    }
  }

  .tablet-mode & {
    height: 35px;

    .mat-slide-toggle-bar {
      @include rounded-corner(25px);

      width: 64px;
      height: 35px;
    }

    .mat-slide-toggle-thumb {
      width: 25px;
      height: 25px;
    }

    .mat-slide-toggle-thumb-container {
      top: 5px;
      left: 3px;
    }

    &.mat-checked {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(32px, 0, 0) !important;
      }
    }
  }
}

// fix textarea border-top disappearing
.textarea-container {
  padding-top: 2px;
}

mat-error {
  color: $red-6 !important;
  font-family: $primary-font-family;

  .tablet-mode & {
    font-size: 18px;
  }
}

.mat-list-base {
  padding-top: 0;
}

.mat-select-panel {
  .mat-optgroup {
    .mat-optgroup-label {
      display: flex;
      align-items: center;
      height: 2.5em;
      background-color: rgba($deep-blue-6, 3%);
      color: rgba($deep-blue-6, 70%);
      font-size: 11px;
      letter-spacing: 0.08rem;
    }

    .mat-option:not(.mat-option-multiple) {
      padding-left: 16px;
    }
  }
}

// Material Accordion Arrow styles
.mat-expansion-panel-header {
  .mat-expansion-indicator {
    &::after {
      color: $greyish-blue-4;
    }

    &:hover {
      &::after {
        color: $greyish-blue-6;
      }
    }
  }
}

// Roadway Status Accordion styles
.segment-details-card {
  .mat-expansion-panel-header,
  .mat-panel-title {
    overflow: hidden;
    max-height: 34px;
  }

  .mat-expansion-panel-header .mat-expansion-indicator {
    &::after {
      content: '';
      vertical-align: text-top;
      padding: 5px;
    }
  }

  .mat-expansion-panel.show-more__panel:not([class*='mat-elevation-z']) {
    box-shadow: 0 -1px 8px rgba(15, 24, 50, 8%);

    .mat-expansion-panel-header {
      box-shadow: 0 -1px 8px rgba(15, 24, 50, 8%);
    }
  }
}
