@import 'libs/scss/style';

.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      owl-date-time-container {
        @include rounded-corner(2px);

        top: 5px;
        left: -10px;
        width: 250px;
        box-shadow: 0 0 9px rgba($deep-blue-6, 14%);
        font-family: $primary-font-family;

        .owl-dt-calendar-control {
          margin: 5px 0;
        }

        .owl-dt-control-period-button .owl-dt-control-button-content {
          color: var(--wc-deep-blue-6-to-greyscale-3);
          font-weight: bold;
          font-size: 12px;
          font-family: $primary-font-family;
          text-transform: uppercase;
        }

        .owl-dt-control-arrow-button .owl-dt-control-button-content {
          color: var(--wc-greyish-blue-5-to-white-alpha-7);
          font-weight: 900;
          font-size: 14px;
          font-family: $primary-font-family;
        }

        .owl-dt-control-period-button .owl-dt-control-button-arrow {
          fill: var(--wc-deep-blue-6-to-greyscale-3);
        }

        .owl-dt-container-inner {
          .owl-dt-container-row {
            height: 280px !important;
          }

          .owl-dt-container-buttons button {
            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              width: 100%;
              color: var(--wc-white-to-black-alpha-7);
              font-size: 14px;
              font-family: $primary-font-family;
            }
          }

          .owl-dt-container-buttons {
            height: 35px;
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
            background: var(--wc-deep-blue-6-to-light-blue-3-8);
            font-family: $primary-font-family;
          }
        }

        .owl-dt-calendar-table {
          .owl-dt-calendar-header .owl-dt-weekdays th {
            color: $dark-grey-2;
            font-size: 12px;
          }

          &.owl-dt-calendar-multi-year-table {
            .owl-dt-calendar-header {
              display: none;
            }
          }

          .owl-dt-calendar-cell > * {
            @include rounded-corner(5px);

            border: none;
            box-shadow: none !important;
            font-size: 12px;
          }

          .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
          :not(.owl-dt-calendar-cell-disabled):hover
            > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
            background-color: var(--wc-grey-3-to-greyscale-7);
          }

          .owl-dt-calendar-cell-selected {
            background-color: var(--wc-light-blue-5-to-light-blue-2-7) !important;
            color: var(--wc-white-to-black-alpha-7);
          }

          &.owl-dt-calendar-multi-year-table,
          &.owl-dt-calendar-year-table {
            width: 60%;
            margin: 0 auto;

            .owl-dt-calendar-cell > * {
              width: 35px;
              height: 27px;
            }
          }

          &.owl-dt-calendar-year-table {
            margin: 20% auto 0;
          }

          .owl-dt-calendar-cell-today {
            background-color: var(--wc-light-blue-0-to-light-blue-2-2);
          }

          .owl-dt-calendar-cell-out {
            color: $dark-grey-1;
            opacity: 1;
          }

          .owl-dt-calendar-header {
            .owl-dt-calendar-table-divider {
              display: none;

              &::after {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
