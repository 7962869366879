// TODO: remove important property from this file
.rf-weather-alerts-modal {
  .mat-accordion {
    margin-bottom: 50px;
  }

  .mat-expansion-panel {
    @include rounded-corner(0);

    overflow: unset;
    margin: 0;
    border-bottom: 1px solid var(--wc-grey-4-to-white-alpha-2) !important;
    box-shadow: none;

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        margin-top: -12px;
        padding: 0;
      }
    }
  }

  .mat-expansion-panel-header {
    height: 59px !important;
    padding: 20px 0;
    background-color: var(--wc-white-to-white-alpha-1-half) !important;

    .mat-content {
      .mat-expansion-panel-header-title {
        color: var(--wc-deep-blue-6-to-greyscale-3);
        font-size: 14px;
        font-family: $primary-font-family-bold;
      }
    }

    .mat-expansion-indicator {
      &::after {
        border-width: 0 3px 3px 0;
        color: var(--wc-black-alpha-7-to-white-alpha-7);
        font-weight: 900;
        font-size: 14px;
      }

      &:hover::after {
        color: var(--wc-deep-blue-6-to-greyscale-3);
      }
    }

    &:hover {
      background-color: var(--wc-white-to-dark-greyish-blue-9);
    }
  }
}
