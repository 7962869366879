.autocomplete-select-panel {
  &.mat-select-panel {
    overflow-x: hidden;
    margin-left: 16px;

    &.involved-vehicles-dropdown {
      width: 204px;
      min-width: initial !important;

      &.tablet-mode {
        width: 238px;
      }
    }

    &.milemarker {
      width: 196px;
      min-width: initial !important;
    }

    .mat-option {
      height: 36px;
      padding: 0 12px;
      background: transparent !important;
      font-size: 14px;
      line-height: 36px;

      &:hover {
        color: var(--wc-light-blue-6-to-light-blue-2-8);
      }

      &.mat-selected.mat-active {
        background: var(--wc-white-to-dark-greyish-blue-9) !important;
        color: var(--wc-light-blue-6-to-light-blue-3-8);

        &:hover {
          color: var(--wc-light-blue-6-to-light-blue-2-8);
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      padding: 0;
      border-top: 0;
    }

    // Multi-select styles
    .mat-pseudo-checkbox {
      width: 20px;
      height: 20px;
      margin-right: 12px;

      &.mat-pseudo-checkbox-checked {
        background-color: var(--wc-light-blue-6-to-light-blue-3-8);
      }

      &::after {
        top: 2px;
        left: 2px;
        width: 10px;
        height: 6px;
      }

      & + .mat-option-text {
        color: var(--wc-deep-blue-6-to-greyscale-3);
        font-family: $primary-font-family;
      }
    }
  }

  // Autocomplete placeholder styles
  .mat-form-field .mat-form-field-label,
  .mat-form-field.mat-focused .mat-form-field-label {
    color: $greyscale-3;
  }

  .autocomplete-search-input {
    &.mat-input-element {
      height: 36px;
      margin-top: 0;
      padding: 0 36px 0 12px;
      border-bottom: 1px solid var(--wc-grey-4-to-white-alpha-2);
      color: var(--wc-deep-blue-6-to-greyscale-3);
      font-size: 14px;
    }

    &:focus {
      @include rounded-corner(0);

      border: 1px solid var(--wc-light-blue-5-to-light-blue-3-8);
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }

  .placeholder {
    color: $greyscale-3;
  }

  .option-no-results-found-autocomplete {
    &.mat-option {
      font-size: 14px;

      .mat-pseudo-checkbox + .mat-option-text {
        color: $greyscale-5;
      }

      &.tablet-mode {
        font-size: 24px;
      }

      &.loading-options {
        position: relative;
        padding: 0;
        padding-bottom: 20px;
      }
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }

  .autocomplete-input-container {
    position: relative;
  }

  .autocomplete-search-input {
    width: -webkit-fill-available !important;
  }

  .autocomplete-search-input,
  .clear-autocomplete-button {
    & + .mat-form-field-label-wrapper {
      top: -7px;
      left: 12px;
    }
  }

  .clear-autocomplete-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    font-size: 16px;
    line-height: 36px;
  }

  .multi-select-submit-button {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: var(--wc-deep-blue-6-to-light-blue-3-6);
    font-size: 14px;
  }

  .mat-form-field-suffix {
    padding-right: 2px !important;
  }

  .autocomplete-close-button {
    margin-right: -2px !important;

    .autocomplete-close-button-icon {
      color: $greyscale-5;
      font-size: 16px !important;
    }
  }

  .mat-input-element {
    font-size: 14px;
    font-family: $primary-font-family;
  }

  &.tablet-mode {
    @include rounded-corner(8px);

    max-width: 448px;
    max-height: 370px;
    margin-left: 16px;

    &.milemarker {
      @include screen(lg) {
        width: 224px;
        margin-left: 16px;
      }

      width: 190px;
      margin-left: 42px;

      @media (orientation: landscape) {
        width: 274px;
      }
    }

    &.mitigation {
      width: 100%;
      min-width: initial !important;
      max-width: initial;
      margin-left: 40px;
    }

    &.incident-type {
      min-width: 100% !important;
      margin-left: 15px !important;
    }

    .mat-option {
      height: 80px;
      padding: 0 17px;
      font-size: 24px;
      line-height: 60px;

      .mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
        border: solid 2px var(--wc-deep-blue-6-to-greyscale-3);
        transition: inherit !important;
      }

      .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
        border-color: var(--wc-light-blue-6-to-light-blue-3-8);
      }
    }

    .autocomplete-search-input {
      height: 60px;
      padding: 0 54px 0 17px;
      font-size: 24px;

      &:focus {
        border: 2px solid var(--wc-light-blue-5-to-light-blue-3-8);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    // Placeholder styles for autocomplete input
    .autocomplete-search-input,
    .clear-autocomplete-button {
      & + .mat-form-field-label-wrapper {
        top: 0;
        left: 0;
        padding: 0;

        .mat-form-field-label {
          position: initial;
          height: 60px;
          padding-left: 17px;
          font-size: 24px;
          line-height: 60px;
        }
      }
    }

    .clear-autocomplete-button {
      width: 60px;
      height: 54px;
      font-size: 35px;
      line-height: 54px;
    }

    .multi-select-submit-button {
      height: 62px;
      font-size: 26px;
    }

    .mat-input-element {
      font-size: 24px;
      font-family: $primary-font-family;
    }

    .mat-select-value-text {
      font-size: 24px;
      line-height: 27px;
    }

    .mat-select-placeholder {
      font-size: 24px;
    }
  }
}
