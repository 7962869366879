.filters-panel {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate,
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background-color: var(--wc-light-blue-6-to-light-blue-3-8) !important;

    .mat-checkbox-frame {
      border-color: $light-blue-8 !important;
    }
  }

  .mat-checkbox-frame {
    border-color: var(--wc-black-alpha-5-to-white-alpha-5);
  }
}
