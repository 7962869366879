.rf-crash-risk-panel {
  .mat-select {
    height: 24px;
    border-bottom: 1px solid $greyscale-5;
    line-height: 24px;
  }

  .mat-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 17px;
        height: 17px;
      }
    }
  }

  .cdk-overlay-container {
    .cdk-overlay-connected-position-bounding-box {
      .cdk-overlay-pane {
        .mat-menu-panel {
          @include rounded-corner(2px);

          position: absolute;
          top: 5px;
          left: -33px;
          box-shadow: 0 0 9px rgba($deep-blue-6, 14%);

          .mat-menu-content {
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}
