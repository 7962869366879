.rf-map-layers-panel {
  .mat-menu-content {
    padding: 0;
  }

  .mat-checkbox-layout {
    align-items: center;
  }

  .mat-checkbox:not(.mat-checkbox-disabled) {
    .mat-checkbox-frame {
      border-color: var(--wc-black-alpha-5-to-white-alpha-5);
    }

    &:hover {
      color: $light-blue-8;

      .mat-checkbox-frame {
        border-color: $light-blue-8;
      }
    }
  }

  mat-expansion-panel.mat-expansion-panel.checkbox-group {
    @include rounded-corner(0);

    margin: 0;
    background-color: transparent !important;
    box-shadow: none;
  }

  mat-expansion-panel-header.mat-expansion-panel-header.map-layers-list {
    height: 30px !important; //TODO: remove important here, refer to _reset.scss height important as well
    padding-right: 0;
    padding-left: 20px;
    background-color: transparent !important;

    &:hover:not([disabled]) {
      background-color: initial;
    }
  }

  .mat-expansion-panel-body {
    padding: 4px 30px 0;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
    background-color: transparent !important;
  }

  .mat-checkbox-label {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    max-width: 136px;
    font-size: 14px;
    font-family: $primary-font-family;
    text-overflow: ellipsis;
    white-space: nowrap;

    .widget & {
      font-size: 13px;
    }
  }

  .mat-checkbox-inner-container {
    margin: 0 8px;

    .widget & {
      margin: 0 8px 0 3px;
    }
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate,
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background-color: var(--wc-light-blue-6-to-light-blue-3-8);
  }

  .mat-expansion-indicator {
    position: absolute;
    left: 0;

    &::after {
      padding: 0;
      border-top: 5px solid $greyscale-5;
      border-right: 5px solid transparent;
      border-bottom-width: 0;
      border-bottom-style: none;
      border-left: 5px solid transparent;

      // transform: none;
      transform: rotate(270deg);
    }
  }

  .mat-expanded {
    .mat-expansion-indicator {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .mat-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 17px;
        height: 17px;

        .widget & {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar:hover:hover {
  background-color: var(--wc-light-blue-6-to-light-blue-3-8);
}
