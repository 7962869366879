$mini-sidebar-width: 80px;

/*******************
Main sidebar
******************/

mat-sidenav {
  width: 240px;
  padding-top: 0;
}

.settings-sidebar {
  overflow-y: scroll;
  padding-bottom: 100px;
  box-shadow: 1px 0 20px $black-alpha-1;

  .mat-list-item {
    height: auto;

    &:hover {
      background: none;
    }

    .mat-list-item-content {
      display: block;
      padding: 0 15px;

      &:hover {
        background: none;
      }
    }
  }

  .sub-item {
    display: none;
    margin-top: -15px;

    a {
      height: 50px;
      margin-bottom: 0;
      padding-left: 28px;
    }
  }

  .saperator {
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    cursor: text;
  }

  .selected {
    .sub-item {
      display: block;
    }
  }
}

.sidebar-container {
  &.mat-list-base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;

  .profile-img {
    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0 $black-alpha-1;
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px $black-alpha-1;
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px $black-alpha-1;
        opacity: 0;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0 $black-alpha-1;
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px $black-alpha-1;
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px $black-alpha-1;
        opacity: 0;
        transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @include rounded-corner(100%);

    width: 50px;
    margin-left: 30px;
    padding: 31px 0;

    &::before {
      @include rounded-corner(50%);

      content: '';
      position: absolute;
      top: 31px;
      z-index: 0;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
    }

    img {
      @include rounded-corner(100%);

      width: 100%;
    }
  }

  .profile-text {
    position: relative;
    padding: 5px 0;
    white-space: nowrap;
    cursor: pointer;

    > a {
      display: block;
      width: 100%;
      padding: 6px 30px;
      background: $black-alpha-5;
      color: var(--wc-white-to-black-alpha-7) !important;
      white-space: nowrap;

      &::after {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}

/* ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@include screen($breakpoint-xs) {
  .minisidebar #snav {
    .mat-drawer-backdrop {
      display: none;
    }

    &.mat-sidenav ~ .mat-drawer-content {
      margin-left: $mini-sidebar-width;
    }

    &.mat-sidenav {
      transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    }

    &.mat-sidenav:not(:hover) {
      width: $mini-sidebar-width;

      .sub-menu,
      .dd-icon,
      .selected .sub-item {
        display: none;
        visibility: hidden;
      }

      .user-profile {
        .profile-img {
          margin-left: 15px;
        }

        .profile-text {
          visibility: hidden;
        }
      }

      .saperator span {
        display: block;
        overflow: hidden;
        width: 40px;
        text-overflow: ellipsis;
      }

      .mat-list-item > .mat-list-item-content > a {
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }

      .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
      .mat-list-item > .mat-list-item-content > a > .menu-caret {
        display: none;
      }

      .mat-list-item > .mat-list-item-content > a > span.menu-badge {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .mat-list-item > .mat-list-item-content > a > .material-icons {
        width: $mini-sidebar-width !important;
        margin: 0;
        padding: 0;
      }
    }
  }

  [dir='rtl'] {
    app-sidebar {
      display: block;
      overflow: hidden;
    }

    .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
      margin-right: $mini-sidebar-width !important;
      margin-left: 0 !important;
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0 !important;
  }
}
