@import '~shepherd.js/dist/css/shepherd.css';

$guided-tour-left-arrow: #529ae8;
$guided-tour-right-arrow: #0a6eda;
$guided-tour-bottom-arrow: #3786e2;
$guided-tour-bottom-end-arrow: #1474db;
$guided-tour-bottom-start-arrow: #64a4ed;

.default-shepherd {
  background: linear-gradient(136.6deg, #c0dfff -52.04%, #78b2f0 -9.2%, #0a6eda 60.93%);
  font-size: 14px;
  font-family: $primary-font-family;

  &.widget {
    max-width: 220px;
    padding: 16px;
  }

  &.narrow {
    max-width: 234px;

    .shepherd-title,
    .shepherd-text {
      font-size: 14px;
    }
  }

  .shepherd-footer {
    .widget & {
      padding: 0;
      padding-top: 16px;
    }
  }

  &.shepherd-enabled.shepherd-element {
    background-color: $grey-1;

    &[data-popper-placement='right'] {
      .shepherd-arrow::before {
        background: $guided-tour-left-arrow;
      }
    }

    &[data-popper-placement='bottom'] {
      .shepherd-arrow::before {
        background: $guided-tour-bottom-arrow;
      }
    }

    &[data-popper-placement='bottom-end'] {
      .shepherd-arrow::before {
        background: $guided-tour-bottom-end-arrow;
      }
    }

    &[data-popper-placement='bottom-start'] {
      .shepherd-arrow::before {
        background: $guided-tour-bottom-start-arrow;
      }
    }

    .shepherd-arrow {
      &::before {
        top: 2px;
        width: 13px;
        height: 13px;
        background: $guided-tour-right-arrow;
      }
    }
  }

  &.shepherd-has-title .shepherd-content {
    .shepherd-cancel-icon {
      color: $white;
    }

    .shepherd-header {
      position: relative;
      display: flex;
      gap: 4px;
      align-items: baseline;
      padding: 10px 18px;
      background: transparent;
      font-size: 14px;

      .widget & {
        padding: 0;
        padding-bottom: 10px;
        font-size: 12px;
        font-family: $primary-font-family-bold;
      }
    }

    .shepherd-text {
      padding: 12px 18px;
      padding-top: 0;
      color: white;

      .widget & {
        padding: 0;
        font-size: 13px;

        .wc-tour-help-center-link {
          color: inherit;
        }
      }
    }
  }

  h3.shepherd-title {
    margin: 0;
    color: $white;
    font-family: $primary-font-family-bold;

    .widget & {
      font-size: 13px;
    }
  }

  .shepherd-button {
    &.final-button,
    &.next-button,
    &.back-button {
      background: $white;
      color: $deep-blue-6;
    }

    &.complete-button,
    &.cancel-button {
      border: 1px solid $white;
      background: transparent;
      color: $white;

      &:hover {
        background-color: $black-alpha-1;
        color: $white;
      }
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(15, 24, 50, 40%);
    }
  }

  .shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow {
    z-index: 1;

    &::before {
      background: $white;
    }
  }
}
