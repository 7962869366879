/* ==============================================================
 For all pages
 ============================================================== */

body {
  overscroll-behavior: contain;
}

/*******************
 Outline Styles
*******************/
.focus-styles {
  @include focus-styles(4px);

  &.negative-outline-offset {
    outline-offset: -2px;
  }
}

.map-element-click-modal {
  &:focus-visible {
    .crash-risk-modal-container {
      @include focus-styles(0);
    }

    .weather-events-details-modal {
      @include focus-styles(0);
    }
  }
}

/*******************
 Main container
*******************/
wc-view-details-panel,
wc-work-request-view-details-panel {
  .fullscreen-image-wrapper.fullscreen-image-wrapper {
    left: initial;
    width: 100vw;
  }
}

.main-container {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mat-toolbar {
  font-family: $primary-font-family;
}

.mat-drawer-container {
  background: var(--wc-grey-2-to-dark-greyish-blue-10);
}

html .page-wrapper {
  height: calc(100vh - #{$main-nav-header-height});

  .page-content {
    height: 100%;
    background: var(--wc-white-to-dark-greyish-blue-9);
  }

  &.disabled-page-scroll {
    overflow: hidden;
  }
}

.ngx-datatable.material {
  border: 1px solid rgba(120, 130, 140, 13%);
  box-shadow: none;
}

.bold-text {
  font-family: $primary-font-family-bold;
}

/* Utils */
.clickable {
  cursor: pointer;
}

/* Icons */

.quick-start-header-icon {
  display: block;
  width: 350px;
  height: 393px;
  background: url('/assets/icons/onboarding-tours.svg') no-repeat;
  background-size: 316px;
}

.onboarding-tours-dialog-icon {
  display: block;
  width: 100%;
  height: 100%;
  background: url('/assets/icons/onboarding-tours-dialog.svg') no-repeat;
  background-size: 310px;
}

.doc-file-icon {
  display: block;
  width: 25px;
  height: 25px;
  background: url('/assets/icons/doc-file.svg') no-repeat;
  background-size: 25px;
  cursor: pointer;
}

.icon-details {
  display: block;
  width: 25px;
  height: 25px;
  background: url('/assets/icons/details.svg') no-repeat;
  background-size: 25px;
  cursor: pointer;
}

.icon-edit {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 18px;
  background: url('/assets/icons/edit-white.svg') no-repeat;
  cursor: pointer;
}

.icon-remove {
  display: inline-block;
  width: 13px;
  height: 16px;
  margin-left: 18px;
  background: url('/assets/icons/trash-bin-white.svg') no-repeat;
  cursor: pointer;
}

.icon-close {
  position: absolute;
  top: -20px;
  right: -20px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  background: url('/assets/icons/close-white.svg') no-repeat;
  background-size: 12px;
  cursor: pointer;
}

.icon-attention {
  display: inline-block;
  flex-shrink: 0;
  width: 42px;
  height: 34px;
  margin-right: 20px;
  background: url('/assets/icons/attention.svg') no-repeat;

  &.icon-white-color {
    background: url('/assets/icons/attention-white.svg') no-repeat;
  }
}

.sound-alert-bell {
  display: inline-block;
  flex-shrink: 0;
  width: 98px;
  height: 94px;
  margin-bottom: 10px;
  background: url('/assets/icons/bell.svg') no-repeat;
  background-size: cover;
}

.wrong-way-alert-icon {
  display: inline-block;
  flex-shrink: 0;
  width: 72px;
  height: 69px;
  background: url('/assets/icons/wrong_way_alert.svg') no-repeat;
  background-size: cover;
}

.hourglass-icon {
  display: inline-block;
  flex-shrink: 0;
  width: 80px;
  height: 87px;
  background: url('/assets/icons/hourglass.svg') no-repeat;
  background-size: cover;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: $main-nav-header-height;

  .language-rtl & {
    direction: rtl;
  }
}

.settings-component {
  .mat-header-cell,
  .mat-cell {
    color: var(--wc-deep-blue-6-to-greyscale-3);
    font-size: $base-font-size-desktop;
    font-family: $primary-font-family;
  }

  .mat-header-cell {
    font-weight: 600;
    font-size: 14px;
  }
}

.confirm-dialog-modal {
  .mat-dialog-container {
    @include rounded-corner(6px);

    padding: 0;
  }
}

.publish-modal,
.mitigation-form-modal {
  @include rounded-corner(6px);

  .mat-dialog-container {
    padding: 0;
  }
}

.cdk-overlay-pane.analytics-event-dialog {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 562px;
  height: 100%;

  .mat-dialog-container {
    padding: 0;
  }
}

.settings-form-modal,
.scheduler-form,
.multi-radio-group {
  .admin-form {
    position: relative;
  }

  .mat-dialog-container {
    @include rounded-corner(6px);

    overflow: visible;
    padding: 0;
  }

  .mat-form-field-disabled {
    .mat-form-field-underline {
      height: 0;
    }
  }

  .mat-input-element {
    &:disabled {
      color: var(--wc-black-to-white-alpha-7);
    }
  }

  .textarea-control {
    .mat-input-element {
      &:disabled {
        border: none;
      }
    }
  }

  .mat-select-disabled {
    .mat-select-value {
      // color: var(--wc-deep-blue-6-to-greyscale-3);
    }

    .mat-select-arrow-wrapper {
      visibility: hidden;
    }
  }

  .role-form {
    .textarea-control {
      .field-label {
        font-size: $base-font-size-desktop;
        font-family: $primary-font-family;
      }
    }
  }

  .mat-radio-button {
    .mat-radio-outer-circle {
      width: 16px;
      height: 16px;
      border-width: 1px;
    }

    .mat-radio-inner-circle {
      width: 16px;
      height: 16px;
    }

    .mat-radio-ripple {
      top: calc(50% - 16px);
      left: calc(50% - 16px);
      width: 32px;
      height: 32px;
    }

    &.mat-accent {
      &.mat-radio-checked {
        .mat-radio-outer-circle {
          border-color: $light-blue-8;
        }
      }

      .mat-radio-inner-circle {
        background-color: var(--wc-light-blue-6-to-light-blue-3-8);
      }
    }
  }

  .permissions-section {
    .radio-group {
      .field-label {
        font-size: $base-font-size-desktop;
        font-family: $primary-font-family;
      }
    }
  }

  .mat-slide-toggle {
    font-size: $base-font-size-desktop;

    .mat-slide-toggle-bar {
      margin-left: 140px;
    }

    .mat-slide-toggle-content {
      font-family: $primary-font-family;
    }
  }
}

// Angular Material select dropdown fix
.select-dropdown {
  @include rounded-corner(2px);

  width: 100%;
  min-width: 200px !important;
  max-width: 326px !important;
  margin-top: 21px !important;
  margin-left: 40px;
  border: 1px solid $greyscale-5;

  &.tablet-mode {
    max-width: 416px !important;
    margin-top: 38px !important;

    .mat-option {
      height: 50px !important;
    }
  }
}

.fixed-select-dropdown {
  @include rounded-corner(2px);

  width: 100%;
  min-width: 200px !important;
  max-width: 326px !important;
  margin-top: 25px;
  margin-left: 16px;
  border: 1px solid $greyscale-5;

  &.full-width {
    max-width: unset !important;
  }

  &.tablet-mode {
    min-width: 100% !important;
    max-width: 450px !important;
    margin-left: 16px;
  }

  &.language-rtl {
    text-align: right;
    direction: rtl;
  }

  &.user-form-select {
    margin-top: 24px;
    margin-left: 16px;
  }
}

.sort-select-dropdown {
  @include rounded-corner(0);

  width: 102px;
  min-width: initial;
  margin-top: 36px;
  margin-left: 16px;
  border: 1px solid var(--wc-disabled-to-white-alpha-2);
  font-family: $primary-font-family;
  text-transform: capitalize;

  .mat-active {
    background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
    color: $light-blue-8 !important;
  }

  &.priority {
    margin-top: 85px !important;
  }
}

.segment-select-dropdown {
  @include rounded-corner(0 !important);

  width: 180px;
  min-width: initial;
  margin-top: 36px !important;
  margin-left: 16px;
  border: 1px solid var(--wc-disabled-to-white-alpha-2);
  font-family: $primary-font-family;

  .mat-active {
    background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
    color: $light-blue-8 !important;
  }

  &.priority {
    margin-top: 85px !important;
  }

  .mat-select-placeholder {
    color: var(--wc-black-alpha-9-to-white-alpha-9);
  }
}

.mat-horizontal-content-container {
  overflow: auto !important;
  height: calc(
    100vh - #{$main-nav-header-height} - #{$incident-title-height} - #{$incident-footer-height} - #{$incident-stepper-height}
  );
  padding: 12px !important;
  background-color: var(--wc-grey-2-to-dark-greyish-blue-3);
  box-shadow: 5px 0 10px rgb(0 0 0 / 10%);

  //.last-step & {
  //  height: calc(100vh - #{$main-nav-header-height} - #{$incident-title-height} - #{$incident-footer-height}
  //  - #{$incident-stepper-height});
  //}
}

.mat-checkbox-layout {
  font-size: small;
  font-family: $primary-font-family;
  white-space: normal !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 20px !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #67757c !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #028ee1 !important;
}

wc-view-incident,
wc-incident,
wc-incident-desktop,
wc-step-two,
wc-traffic-disruption-view,
wc-work-request-step-one-desktop,
wc-work-request-step-two-desktop,
wc-traffic-disruption,
wc-road-closure-form,
wc-tablet-vertical-stepper,
wc-view-traffic-disruption,
wc-view-details-panel,
wc-work-request-view-details-panel,
wc-work-request-view,
wc-traffic-disruption-details-view,
wc-incident-view {
  .mat-expansion-panel-body {
    padding: 8px 26px 15px 47px;
    color: var(--wc-deep-blue-6-to-greyscale-3);
  }

  .mat-expansion-panel {
    margin-bottom: 20px !important;
    box-shadow: 0 5px 5px rgba($greyish-blue-5, 10%) !important;

    .widget & {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      border-radius: 6px !important;
    }

    &:hover {
      outline: solid 1px $deep-blue-3;
    }

    .mat-expansion-indicator {
      &::after {
        color: var(--wc-greyish-blue-4-to-greyscale-4) !important;
      }
    }
  }

  .mat-expansion-panel-header {
    padding-left: 46px;

    .widget & {
      padding-left: 36px;
    }
  }

  .mat-expansion-panel-header-title {
    color: var(--wc-deep-blue-3-to-greyscale-4) !important;
  }
}

wc-response-plan-entity-control {
  .mat-expansion-panel-body {
    padding: 0 15px 15px 21px;
  }
}

wc-response-plan-view {
  .mat-expansion-panel-body {
    padding: unset;
  }
}

.validation-error {
  padding: 8px 16px 8px 0;
  color: $red-6;
  font-family: $primary-font-family;

  .tablet-mode & {
    padding: 16px 0;
    font-size: 20px;
    line-height: 40px;
  }
}

.form-subsection {
  @include rounded-corner(6px);

  position: relative;
  margin-bottom: 12px;
  padding: 16px;
  background-color: var(--wc-grey-1-to-dark-greyish-blue-6);

  .tablet-mode & {
    padding: 16px 32px 16px 16px;
  }
}

.form-subsection-title {
  margin: 0;
  padding: 0;
  color: var(--wc-deep-blue-6-to-greyscale-3);
  font-weight: 600;
  font-size: 14px;

  .tablet-mode & {
    font-size: 20px;
    line-height: 24px;
  }
}

.button-add {
  position: absolute;
  top: 5px;
  right: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  border: none;
  background: none;
  color: var(--wc-greyish-blue-4-to-light-blue-3-5);
  font-size: 14px;
  font-family: $primary-font-family-bold;
  cursor: pointer;
  user-select: none;

  .tablet-mode & {
    top: 24px;
    right: 78px;
    font-size: 24px;
    line-height: 30px;
  }

  &:disabled {
    color: $disabled;
    cursor: not-allowed;
  }
}

.button-add-icon {
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;

  .tablet-mode & {
    margin-right: 10px;
    font-size: 22px;
  }

  :disabled & {
    color: $disabled;
  }
}

.remove-section-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 4px;
}

.remove-section-button {
  padding: 0;
  border: none;
  background: none;
  color: var(--wc-greyish-blue-5-to-red-4);
  cursor: pointer;

  .tablet-mode & {
    margin-top: 26px;
  }
}

.icon-remove-circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;

  .tablet-mode & {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    font-size: 22px;
  }
}

.remove-button-title {
  font-size: 14px;
  text-transform: uppercase;

  .tablet-mode & {
    font-size: 24px;
    line-height: 30px;
  }
}

// Checkbox
::ng-deep {
  .mat-checkbox-indeterminate,
  .mat-checkbox-checked {
    &.mat-accent {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          background-color: var(--wc-light-blue-8-to-light-blue-5);
        }
      }
    }
  }
}

.checkbox-control,
.radio-group {
  .mat-error {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    font-family: Open Sans Hebrew, Poppins, sans-serif;
  }
}

.checkbox-control {
  .mat-error {
    margin-left: 12px;
  }
}

.invalid-field {
  .mat-select-value {
    color: $red-6;
  }

  .mat-form-field-underline {
    background-color: $red-6;
  }

  .mat-checkbox-frame {
    border: 2px solid $red-6;
  }

  .field-label {
    color: $red-6;
  }

  .textarea-field {
    border: 1px solid $red-6;
  }
}

// Toaster notifications

// Incident create/edit stepper
.mat-stepper-horizontal {
  background: $greyscale-2;
}

.incidents-panel {
  .icon-close {
    @include rounded-corner(50%);

    position: absolute;
    top: 6px;
    right: 6px;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 6px;
    border: 1px solid gray;
    background: url('/assets/icons/close-dark.png') no-repeat;
    background-position: center;
    background-size: 18px;
    cursor: pointer;
  }
}

.stepper-container {
  position: relative;
  height: calc(100vh - 140px);

  .stepper-create-title,
  .form-create-title {
    display: flex;
    border-bottom: 1px solid var(--wc-grey-3-to-white-alpha-1);
    border-radius: 4px;
    background-color: var(--wc-white-to-dark-greyish-blue-9);

    .sidebar-toggle-button {
      position: absolute;
      top: 20px;
      right: 10px;
      z-index: 3;
      cursor: pointer;
    }
  }

  .stepper-title,
  .form-title {
    display: flex;
    align-items: center;
    margin: 12px;
    color: var(--wc-deep-blue-6-to-greyscale-3);
    font-size: 22px;
    font-family: $primary-font-family;
  }

  .section-panel {
    position: relative;
  }

  &.portrait-mode {
    //height: calc(50vh - 140px);
    height: 100%;
  }
}

.section-icon {
  position: absolute;
  left: 12px;
  width: 25px;
  height: 25px;
  font-style: normal;
  text-align: center;

  &::before {
    padding: 5px;
  }

  &:not(wc-icon) {
    top: 14px;
  }

  .tablet-mode & {
    top: 0;
    font-size: 19px;

    &:not(wc-icon) {
      top: 26px;
    }
  }

  .widget & {
    top: 16px;
  }

  &.dms-icon {
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    line-height: 1;
    text-align: center;

    &::before {
      content: '\e906';
      font-family: $waycare-font-icons;
    }
  }

  &.location-icon {
    .tablet-mode & {
      top: 26px;
    }
  }

  &.related-incidents-icon {
    top: 8px;
    font-weight: 400;
    font-size: 22px;
    line-height: 1;
    text-align: center;

    .tablet-mode & {
      top: 20px;
      font-size: 32px;
    }

    &::before {
      content: '\e919';
      font-family: $waycare-font-icons;
    }
  }

  &.auto-publish-icon {
    top: 8px;
    font-weight: 400;
    font-size: 22px;
    line-height: 1;
    text-align: center;

    .tablet-mode & {
      top: 20px;
      font-size: 32px;
    }

    &::before {
      content: '\e923';
      font-family: $waycare-font-icons;
    }
  }

  &.units-icon {
    top: 8px;
    left: 10px;
    font-size: 20px;
    line-height: 1;

    .tablet-mode & {
      top: 20px;
    }

    &::after {
      content: '\e908';
      font-size: 28px;
      font-family: $waycare-font-icons;

      .tablet-mode & {
        font-size: 34px;
      }
    }
  }

  &.purple {
    color: $insight-purple;
  }
}

.add-media-title {
  padding-right: 4px;
}

.media-small-text {
  font-size: 12px;
}

.tablet-mode {
  .incident-section.invalid-panel {
    outline: solid 1px $red-6;
  }
}

::ng-deep {
  mat-stepper-horizontal {
    padding: 1em;
    border: dashed 1px blue;
  }

  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header {
      padding: 12px 24px 10px !important;

      &:not(:first-child)::before,
      &:not(:last-child)::after {
        width: calc(50% - 25px) !important;
      }

      .mat-step-icon.mat-step-icon {
        width: 40px;
        height: 40px;
        font-weight: 600;
        font-size: 20px;

        &.mat-step-icon-state-edit {
          border: 2px solid #02a272;
          background-color: unset;
          color: #02a272;

          .mat-icon {
            font-weight: 600;
          }
        }
      }

      .mat-step-label {
        padding-top: 6px !important;
        font-family: $primary-font-family;

        &.mat-step-label-active {
          color: var(--wc-deep-blue-6-to-greyscale-3);
          font-weight: 800;
          font-family: $primary-font-family-bold;
        }
      }
    }
  }

  .mat-step-header.mat-step-header {
    .mat-step-icon {
      border: 1px solid $greyscale-5;
      background-color: transparent;
      color: #818181;
      font-weight: 400;

      &.mat-step-icon-selected {
        border: 2px solid $black;
        background-color: var(--wc-white-to-dark-greyish-blue-9);
        color: var(--wc-deep-blue-6-to-greyscale-3);
        font-weight: 600;
      }
    }
  }

  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header:not(:first-child)::before,
    .mat-horizontal-stepper-header:not(:last-child)::after,
    .mat-stepper-horizontal-line {
      top: 30px !important;
      border-color: #262626;
    }
  }

  .mat-expansion-panel {
    position: relative;

    &.invalid-panel {
      outline: solid 1px $red-6;
    }

    &.driver-shift-section,
    &.create-shift-section {
      @include rounded-corner(6px);

      padding-left: 6px;
      box-shadow: 0 5px 5px rgb(15 24 50 / 10%);

      .mat-expansion-panel-header {
        padding-left: 46px;
      }
    }
  }

  .section-panel {
    border: 2px solid transparent;

    .validation-error {
      position: absolute;
      top: 10px;
      right: 10px;
      border: 1px solid $greyscale-5;
      background-color: #fff2cc;
      color: var(--wc-deep-blue-6-to-greyscale-3) !important;
      font-size: $validation-error-font-size;
    }
  }

  .invalid-section {
    border: 2px solid #ffd966;
  }

  .mat-expansion-panel-header-title {
    font-size: 14px;
    font-family: $primary-font-family-bold;
  }

  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header-container {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: var(--wc-white-to-dark-greyish-blue-9);
      box-shadow: 0 3px 5px 0 rgb(0 0 0 / 36%);
      box-shadow: 0 3px 5px 0 rgb(0 0 0 / 36%);
      box-shadow: 0 3px 5px 0 rgb(0 0 0 / 36%);
    }
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: var(--wc-light-blue-6-to-light-blue-3-8);
  }

  .mat-form-field.mat-form-field-invalid.mat-focused .mat-form-field-ripple {
    background: var(--wc-red-5-to-red-3-9);
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $light-blue-8;
  }
}

.stepper-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $side-panel-width;
  height: $incident-footer-height;
  padding: 0 28px 0 14px;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  text-align: center;

  &.tablet-mode {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    width: 100%;

    //box-shadow: 0px -4px 10px rgba($deep-blue-6, 0.2);
    height: 1px;
    background-color: transparent;
    box-shadow: 0 -2px 4px rgb(15 24 50 / 50%);
  }

  .portrait-mode & {
    position: absolute;
  }

  .portrait-mode &:not(.incident-has-insight) {
    margin: auto;
  }
}

.tablet-mode {
  .mat-expansion-panel {
    &.incident-section {
      .mat-expansion-panel-header {
        padding-left: 50px;
      }
    }
  }
}

.section-description {
  margin-left: 6px;
  color: $greyscale-5;
  font-weight: 400;
  font-family: $primary-font-family;

  .tablet-mode & {
    @include screen(sm) {
      font-size: 20px;
    }

    @include screen(md) {
      font-size: 26px;
    }
  }
}

.required-section {
  padding-right: 4px;
  color: $red-6;
}

// Buttons
.primary-button,
.secondary-button {
  @include rounded-corner(3px);

  box-sizing: content-box;
  min-width: 75px;
  height: 30px;
  margin-left: 10px;
  font-size: 14px;
  font-family: $primary-font-family;
  line-height: 30px;
  text-align: center;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover:not(:disabled) {
    box-shadow: 0 0 5px rgb(15 24 50 / 40%);
  }

  ::ng-deep .mat-button-wrapper {
    font-weight: 400;
  }
}

.primary-button {
  border: 1px solid var(--wc-deep-blue-6-to-none);
  background-color: var(--wc-deep-blue-6-to-light-blue-3-8);
  color: var(--wc-white-alpha-9-to-black-alpha-9);

  &:disabled {
    border: 1px solid var(--wc-disabled-to-white-alpha-2);
    background-color: var(--wc-disabled-to-white-alpha-2);
    color: var(--wc-white-alpha-9-to-black-alpha-9);
    cursor: default;
  }

  &.button-arrow-right {
    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 10px;
      background-image: url('/assets/icons/button-arrow-white.svg');

      .tablet-mode & {
        vertical-align: middle;
      }
    }
  }
}

.secondary-button {
  border: 1px solid var(--wc-deep-blue-6-to-greyscale-3);
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  color: var(--wc-deep-blue-6-to-greyscale-3);

  &:disabled {
    border: 1px solid var(--wc-disabled-to-white-alpha-2);
    background-color: var(--wc-disabled-to-white-alpha-2);
    color: var(--wc-white-to-black-alpha-7);
  }

  &.button-arrow-left {
    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-right: 10px;
      background-image: url('/assets/icons/button-arrow-dark.svg');

      .tablet-mode & {
        vertical-align: middle;
      }
    }

    &:disabled {
      &::before {
        background-image: url('/assets/icons/button-arrow-white.svg');
        transform: rotate(180deg);
      }
    }
  }

  &.button-arrow-right {
    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 10px;
      background-image: url('/assets/icons/button-arrow-dark.svg');
      transform: rotate(180deg);

      .tablet-mode & {
        vertical-align: middle;
      }
    }

    &:disabled {
      &::after {
        background-image: url('/assets/icons/button-arrow-white.svg');
        transform: rotate(0deg);
      }
    }
  }
}

.stepper-footer {
  z-index: 3;

  .primary-button,
  .secondary-button {
    min-width: 90px;
    padding: 0 10px;
  }
}

// Map Buttons - Satellite / Traffic
.map-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  box-sizing: border-box;
  place-content: center space-between;

  &.tablet-mode {
    gap: 20px;

    // width: 170px;
  }
}

.map-icon-button-container {
  @include rounded-corner(6px);

  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 45px;
  height: 45px;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  box-shadow: 4px 4px 25px rgb(15 24 50 / 25%);
  cursor: pointer;

  .widget & {
    width: 33px;
    height: 33px;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    color: var(--wc-greyish-blue-5-to-white-alpha-7);
    font-size: 21px;

    &:not(.tablet) {
      color: $deep-blue-6;
    }

    .widget & {
      font-size: 14px;
    }
  }

  &.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: $light-blue-8;
  }

  &:hover {
    background-color: $light-blue-2;

    .mat-button-toggle-focus-overlay {
      display: none;
    }

    i {
      color: $light-blue-8;
    }
  }

  &.is-opened-layer {
    background: $greyish-blue-5;

    i {
      color: var(--wc-white-to-black-alpha-7);
    }
  }

  .notification-badge {
    @include rounded-corner(50%);

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 20px;
    height: 20px;
    margin: -5px -5px 0 0;
    padding: 4px;
    background: $light-blue-7;
    color: var(--wc-white-to-black-alpha-7);
    font-size: 14px;
    font-family: $primary-font-family;

    &.active {
      background-color: var(--wc-white-to-black-alpha-7);
      color: $light-blue-7;
    }
  }

  &.coming-soon {
    pointer-events: none;

    i {
      color: $disabled;
    }
  }
}

.map-button-wrapper {
  @include rounded-corner(6px);

  width: $map-data-layers-buttons-height;
  width: 45px;
  height: 45px;
  padding: 2px;
  background: var(--wc-white-to-white-4);
  box-shadow: 4px 4px 25px rgb(15 24 50 / 25%);

  .tablet-mode & {
    width: 68px;
    height: 68px;
  }

  &:hover,
  &:focus {
    background: var(--wc-grey-2-to-white-7);
  }

  .map-button-image {
    @include rounded-corner(5px);

    box-sizing: border-box;
    width: 39px;
    height: 39px;
    margin: 1px;
    border: none;
    cursor: pointer;

    .tablet-mode & {
      width: 56px;
      height: 55px;
      margin: 4px;
    }

    &.button-satellite {
      background: url('/assets/icons/satellite.svg') no-repeat;
      background-size: cover;
    }

    &.button-traffic {
      background: url('/assets/icons/traffic.svg') no-repeat;
      background-size: cover;
    }

    &.button-mile-markers {
      background: url('/assets/icons/mile_markers.svg') no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: $greyish-blue-5;

    .map-button-image {
      position: relative;

      &::before {
        @include rounded-corner(50%);

        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        display: block;
        width: 16px;
        height: 16px;
      }
    }

    .button-satellite {
      &::before {
        background: url('/assets/icons/selected-layer.svg') no-repeat;
        background-color: $white;
        background-size: 16px;
      }
    }

    .button-traffic {
      &::before {
        background: url('/assets/icons/selected-layer.svg') no-repeat;
        background-color: $white;
        background-size: 16px;
      }
    }

    .button-mile-markers {
      &::before {
        background: url('/assets/icons/selected-layer.svg') no-repeat;
        background-color: $white;
        background-size: 16px;
      }
    }
  }
}

// Custom scroll
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: $scrollbar-bg-color;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  @include rounded-corner(5px);

  background: var(--wc-disabled-to-white-alpha-2);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $scrollbar-bg-color;
}

// Incidents
.incident-type-icon {
  &.list-icon-crash-unconfirmed {
    background: url('/assets/icons/list_crash.unconfirmed.svg') no-repeat;
  }

  &.list-icon-crash {
    background: url('/assets/icons/list_crash.svg') no-repeat;
  }

  &.list-icon-debris-unconfirmed {
    background: url('/assets/icons/list_debris.unconfirmed.svg') no-repeat;
  }

  &.list-icon-debris {
    background: url('/assets/icons/list_debris.svg') no-repeat;
  }

  &.list-icon-abandoned_vehicle-unconfirmed {
    background: url('/assets/icons/list_abandoned_vehicle.unconfirmed.svg') no-repeat;
  }

  &.list-icon-abandoned_vehicle {
    background: url('/assets/icons/list_abandoned_vehicle.svg') no-repeat;
  }

  &.list-icon-stalled_vehicle-unconfirmed {
    background: url('/assets/icons/list_disabled_vehicle.unconfirmed.svg') no-repeat;
  }

  &.list-icon-stalled_vehicle {
    background: url('/assets/icons/list_disabled_vehicle.svg') no-repeat;
  }

  &.list-icon-vehicle_on_fire-unconfirmed {
    background: url('/assets/icons/list_vehicle_on_fire.unconfirmed.svg') no-repeat;
  }

  &.list-icon-vehicle_on_fire {
    background: url('/assets/icons/list_vehicle_on_fire.svg') no-repeat;
  }

  &.list-icon-left_on_arrival-unconfirmed {
    background: url('/assets/icons/list_left_on_arrival.unconfirmed.svg') no-repeat;
  }

  &.list-icon-left_on_arrival {
    background: url('/assets/icons/list_left_on_arrival.svg') no-repeat;
  }

  &.list-icon-ems-unconfirmed {
    background: url('/assets/icons/list_ems.unconfirmed.svg') no-repeat;
  }

  &.list-icon-ems {
    background: url('/assets/icons/list_ems.svg') no-repeat;
  }

  &.list-icon-police_activity-unconfirmed {
    background: url('/assets/icons/list_police_activity.unconfirmed.svg') no-repeat;
  }

  &.list-icon-police_activity {
    background: url('/assets/icons/list_police_activity.svg') no-repeat;
  }

  &.list-icon-hazard-unconfirmed {
    background: url('/assets/icons/list_hazard.unconfirmed.svg') no-repeat;
  }

  &.list-icon-hazard {
    background: url('/assets/icons/list_hazard.svg') no-repeat;
  }

  &.list-icon-damage-unconfirmed {
    background: url('/assets/icons/list_damage.unconfirmed.svg') no-repeat;
  }

  &.list-icon-damage {
    background: url('/assets/icons/list_damage.svg') no-repeat;
  }

  &.list-icon-traffic_stop-unconfirmed {
    background: url('/assets/icons/list_traffic_stop.unconfirmed.svg') no-repeat;
  }

  &.list-icon-traffic_stop {
    background: url('/assets/icons/list_traffic_stop.svg') no-repeat;
  }

  &.list-icon-other-unconfirmed {
    background: url('/assets/icons/list_other.unconfirmed.svg') no-repeat;
  }

  &.list-icon-other {
    background: url('/assets/icons/list_other.svg') no-repeat;
  }

  &.list-icon-unidentified-unconfirmed,
  &.list-icon-traffic_anomaly-unconfirmed {
    background: url('/assets/icons/list_unknown.unconfirmed.svg') no-repeat;
  }

  &.list-icon-unidentified,
  &.list-icon-unknown_incident_type,
  &.list-icon-traffic_anomaly {
    background: url('/assets/icons/list_unknown.svg') no-repeat;
  }

  &.list-icon-road_closure-unconfirmed {
    background: url('/assets/icons/list_road_closure.unconfirmed.svg') no-repeat;
  }

  &.list-icon-road_closure {
    background: url('/assets/icons/list_road_closure.svg') no-repeat;
  }

  &.list-icon-special_event-unconfirmed {
    background: url('/assets/icons/list_event.unconfirmed.svg') no-repeat;
  }

  &.list-icon-special_event {
    background: url('/assets/icons/list_event.svg') no-repeat;
  }

  &.list-icon-construction-unconfirmed {
    background: url('/assets/icons/list_construction.unconfirmed.svg') no-repeat;
  }

  &.list-icon-construction {
    background: url('/assets/icons/list_construction.svg') no-repeat;
  }

  &.list-icon-wrong_way {
    background: url('/assets/icons/list_wrong_way.svg') no-repeat;
  }

  &.list-icon-wrong_way-unconfirmed {
    background: url('/assets/icons/list_wrong_way.unconfirmed.svg') no-repeat;
  }
}

.incident-type-icon-grey {
  &.list-icon-crash {
    background: url('/assets/icons/list_crash_grey.svg') no-repeat;
  }

  &.list-icon-debris {
    background: url('/assets/icons/list_debris_grey.svg') no-repeat;
  }

  &.list-icon-abandoned_vehicle {
    background: url('/assets/icons/list_abandoned_vehicle_grey.svg') no-repeat;
  }

  &.list-icon-stalled_vehicle {
    background: url('/assets/icons/list_disabled_vehicle_grey.svg') no-repeat;
  }

  &.list-icon-vehicle_on_fire {
    background: url('/assets/icons/list_vehicle_on_fire_grey.svg') no-repeat;
  }

  &.list-icon-left_on_arrival {
    background: url('/assets/icons/list_left_on_arrival_grey.svg') no-repeat;
  }

  &.list-icon-ems {
    background: url('/assets/icons/list_ems_grey.svg') no-repeat;
  }

  &.list-icon-police_activity {
    background: url('/assets/icons/list_police_activity_grey.svg') no-repeat;
  }

  &.list-icon-hazard {
    background: url('/assets/icons/list_hazard_grey.svg') no-repeat;
  }

  &.list-icon-damage {
    background: url('/assets/icons/list_damage_grey.svg') no-repeat;
  }

  &.list-icon-other {
    background: url('/assets/icons/list_other_grey.svg') no-repeat;
  }

  &.list-icon-unidentified,
  &.list-icon-traffic_anomaly {
    background: url('/assets/icons/list_other_grey.svg') no-repeat;
  }

  &.list-icon-road_closure {
    background: url('/assets/icons/list_road_closure_grey.svg') no-repeat;
  }

  &.list-icon-special_event {
    background: url('/assets/icons/list_event_grey.svg') no-repeat;
  }

  &.list-icon-construction {
    background: url('/assets/icons/list_construction_grey.svg') no-repeat;
  }

  &.list-icon-traffic_stop {
    background: url('/assets/icons/list_traffic_stop_grey.svg') no-repeat;
  }
}

// .icon-congestion {
//   &::after {
//     content: '\e910';
//     color: $irregular-congestion;
//     font-weight: 400;
//     font-style: normal;
//     font-size: 28px;
//     font-family: $waycare-font-icons;

//     .tablet-mode & {
//       font-size: 38px;
//     }
//   }
// }

.icon-road-closure {
  position: relative;
  top: 3px;
  margin-left: -5px;

  &::after {
    content: '\e907';
    font-style: normal;
    font-size: 26px;
    font-family: $waycare-font-icons;
  }
}

.incident-buttons-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: var(--wc-box-shadow-main-left);
  text-align: center;

  &:has(.incident-buttons-container__restore) {
    flex-direction: row;
  }
}

.incident-button {
  z-index: 1;
  display: block;
  width: 86px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  font-size: 14px;
  font-family: $primary-font-family-bold;
  line-height: 40px;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: var(--wc-light-blue-2-to-dark-greyish-blue-10);
    box-shadow: 0 0 15px rgb(15 24 50 / 20%);

    &:not(.reject-button) {
      color: $light-blue-6 !important;
    }
  }

  &.confirm-button,
  &.reject-button {
    flex-grow: 1;
  }

  &.confirm-button,
  &.complete-button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: $light-blue-8;

    .fas.fa-undo,
    .fas.fa-check {
      position: absolute;
      top: 44px;
      right: 36px;
      font-weight: 900;

      .tablet-mode & {
        top: 90px;
        right: 65px;
      }
    }

    .tablet-mode & {
      font-size: 24px;
    }
  }

  &.confirm-button {
    border-bottom: 2px solid var(--wc-black-alpha-1-half-to-white-alpha-1-half);
    color: var(--wc-light-blue-6-to-light-blue-3-8);

    &:hover {
      color: $light-blue-7;
    }
  }

  &.reject-button {
    border-bottom-right-radius: 6px;
    color: var(--wc-red-5-to-red-3-9);

    &:hover {
      color: $red-5;
    }
  }

  &.complete-button {
    height: 100%;

    &:hover {
      color: $light-blue-8;
    }
  }

  &.restore-button {
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: var(--wc-greyish-blue-5-to-white-alpha-7);

    &:hover {
      color: var(--wc-deep-blue-6-to-greyscale-3);
    }

    .fas.fa-undo,
    .fas.fa-check {
      position: absolute;
      top: 40px;
      right: 34px;
      font-weight: 900;

      .tablet-mode & {
        top: 90px;
        right: 65px;
      }
    }
  }

  &:disabled {
    &.complete-button,
    &.restore-button,
    &.reject-button,
    &.confirm-button {
      color: var(--wc-disabled-to-white-alpha-2);

      .action-icon-div {
        pointer-events: none;
      }
    }
  }
}

.support-modal-component {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgb(0 0 0 / 25%);

  .fa-life-ring {
    margin-right: 5px;
  }

  .fa-times {
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
  }

  &.tablet-mode {
    .fa-life-ring {
      margin-right: 12px;
      font-size: 22px;
    }

    .fa-times {
      font-size: 31px;
    }
  }

  .support-modal-link {
    color: var(--wc-deep-blue-6-to-greyscale-3);
    font-weight: 900;
  }
}

.support-modal {
  width: 460px;
  margin: auto;
  color: var(--wc-deep-blue-6-to-greyscale-3);
  box-shadow: 0 5px 5px rgb(15 24 50 / 10%);
  font-family: $primary-font-family;

  .tablet-mode & {
    width: 520px;
    box-shadow: 0 4px 70px rgb(15 24 50 / 70%);
  }
}

.support-modal-header {
  @include rounded-corner(6px 6px 0 0);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  background-color: var(--wc-deep-blue-6-to-light-blue-3-8);
  color: var(--wc-white-to-black-alpha-7);
  font-weight: 600;
  font-size: 16px;
  line-height: 60px;

  .tablet-mode & {
    font-size: 24px;
  }
}

.support-modal-body {
  @include rounded-corner(0 0 6px 6px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px 0;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  text-align: center;

  .tablet-mode & {
    height: 280px;
    padding: 30px 30px 50px;
  }
}

.support-modal-paragraph {
  margin: 0;
  padding-top: 28px;
  font-size: 14px;
  line-height: 25px;

  a {
    font-family: $primary-font-family-bold;
  }

  .tablet-mode & {
    font-size: 24px;
    line-height: 32px;
  }

  .phone-number-row {
    font-family: $primary-font-family-bold;
  }

  .version-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    margin: 0 auto;
    margin-top: 38px;
    padding-bottom: 3px;
    border-top: 0.5px solid $dark-grey-2;
    color: $dark-grey-2;
    font-size: 12px;
    font-family: $primary-font-family;
  }
}

///// LOCALIZATION ////
.incidents-list {
  .mat-tab-body-content & {
    .language-rtl & {
      direction: rtl;
    }
  }
}

.incident-buttons-container {
  &.buttons-container-border {
    .language-rtl & {
      border-right: 1px solid var(--wc-black-alpha-1-half-to-white-alpha-1-half);
      border-left: none;
    }
  }
}

::ng-deep .language-rtl {
  .mat-option-text {
    text-align: right;
  }

  .mat-form-field-wrapper {
    text-align: right;
  }
}

.mat-tab-list {
  .language-rtl & {
    direction: rtl;
  }
}

.incident-status {
  display: flex;
  align-items: center;
  align-self: baseline;
  font-size: 12px;
  font-family: $primary-font-family-bold;
  line-height: 15px;

  &.status-unconfirmed {
    color: $red-3;
  }

  &.status-confirmed {
    color: $light-blue-8;
  }

  &.status-completed {
    color: var(--wc-deep-blue-6-to-greyscale-3);
  }

  &.status-rejected {
    color: $greyscale-5;
  }
}

.work-req-status {
  display: flex;
  align-self: flex-end;
  font-size: 12px;
  font-family: $primary-font-family-bold;
  line-height: 12px;

  .tablet-mode & {
    align-items: center;
    align-self: start;
    justify-content: center;
    margin-top: 2px;
    margin-right: 6px;
    font-size: 18px;
  }

  &.status-inactive {
    color: $deep-blue-2;
  }

  &.status-active {
    color: $green-3;
  }

  &.status-completed {
    color: var(--wc-deep-blue-6-to-greyscale-3);
  }
}

.incident-icon-container-view-incident {
  display: flex;
  float: left;
  margin-top: 10px;
  margin-right: 10px;

  .incident-icon {
    width: 54px;
    height: 52px;
    background-size: cover;

    .tablet-mode & {
      position: relative;
      top: -21px;
    }
  }
}

.incident-status-icon {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 4px;
  font-family: $primary-font-family-bold;

  &.icon-unconfirmed {
    background: url('/assets/icons/status-unconfirmed.svg') no-repeat;
  }

  &.icon-confirmed {
    background: url('/assets/icons/status-confirmed.svg') no-repeat;
  }

  &.icon-active {
    background: url('/assets/icons/status-active.svg') no-repeat;
  }

  &.icon-completed {
    background: url('/assets/icons/status-completed.svg') no-repeat;
  }

  &.icon-rejected,
  &.icon-inactive,
  &.icon-planned {
    background: url('/assets/icons/status-rejected.svg') no-repeat;
  }
}

.work-req-status-icon {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 3px;

  .tablet-mode & {
    width: 20px;
    height: 20px;
    margin-left: 6px;
    background-size: cover !important;
  }

  &.icon-inactive {
    background: url('/assets/icons/status-inactive.svg') no-repeat;
  }

  &.icon-completed {
    background: url('/assets/icons/status-completed.svg') no-repeat;
    background-size: 12px;

    .tablet-mode & {
      background-size: cover !important;
    }
  }

  &.icon-active {
    background: url('/assets/icons/status-active.svg') no-repeat;
  }
}

.crash-risk-status {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: $primary-font-family-bold;
  line-height: 14px;

  &.status-active {
    color: $green-8;
  }

  &.status-predicted {
    color: var(--wc-deep-blue-2-to-greyscale-5);
  }

  .tablet-mode & {
    font-size: 18px;
    line-height: 34px;
  }
}

.crash-risk-status-icon {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 5px;

  .tablet-mode & {
    width: 20px;
    height: 20px;
  }

  &.icon-active {
    background: url('/assets/icons/status-active.svg') no-repeat;

    .tablet-mode & {
      background-size: contain;
    }
  }

  &.icon-predicted {
    background: url('/assets/icons/status-inactive.svg') no-repeat;

    .tablet-mode & {
      background-size: contain;
    }
  }
}

.restored-item {
  @include rounded-corner(6px);

  box-sizing: border-box;
  border: 1px solid $green-1;
  background: var(--wc-side-panel-to-greyscale-8);
  box-shadow: 0 0 10px rgb(15 24 50 / 15%);
  filter: drop-shadow(0 0 10px rgb(15 24 50 / 15%));
}

.auto-publish-badge {
  @include rounded-corner(2px);

  align-items: center;
  width: 77px;
  padding: 3px 4px;
  background: $auto-publish-badge-bg;
  color: #5c97ec;
  font-size: 11px;
  font-family: $primary-font-family-bold;
}

.map-layer-icons-container {
  @include rounded-corner(8px);

  position: fixed;
  top: 71px;
  right: 77px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60px;
  padding: 12px 7px;
  background: rgb(255 255 255 / 65%);

  .border-line {
    @include rounded-corner(4px);

    height: 4px;
    margin: 2px 0;
    background: var(--wc-white-to-white-alpha-1-half);
    box-shadow: inset 0 0 2px rgb(31 88 106 / 30%);
  }

  &.data-layer-column {
    top: unset;
    bottom: 136px;
  }

  &.tablet-mode {
    top: unset;
    bottom: 13px;
    z-index: 1;
    width: 190px;
    height: 95px;
  }

  .portrait-mode & {
    top: 0;
    right: 0;
  }
}

.weather-severity-badge {
  @include rounded-corner(2px);

  padding: 2px 3px;
  font-size: 11px;
  font-family: $primary-font-family-bold;

  &.EXTREME {
    background-color: $red-0;
    color: $dark-red-3;
  }

  &.SEVERE {
    background-color: $red-0;
    color: $red-5;
  }

  &.MODERATE {
    background-color: $yellow-0;
    color: #fa8315;
  }

  &.MINOR {
    background-color: $yellow-0;
    color: $yellow-4;
  }

  &.UNKNOWN_WEATHER_ALERT_SEVERITY {
    background-color: var(--wc-grey-2-to-dark-greyish-blue-10);
  }
}

// Transit Icons

.transit_fixed_route_bus_unit,
.transit_fixed_route_bus_unit_my_agency {
  &.status-on_time {
    &::after {
      color: $green-7;
    }
  }

  &.status-early {
    &::after {
      color: $red-3;
    }
  }

  &.status-late {
    &::after {
      color: $greyish-blue-4;
    }
  }
}

.active-bus-status-title {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: $primary-font-family-bold;

  &.status-on_time {
    color: $green-7;
  }

  &.status-early {
    color: $red-3;
  }

  &.status-late {
    color: $greyish-blue-4;
  }
}

.active-bus-status-icon {
  display: block;
  width: 13px;
  height: 13px;
  margin-left: 10px;

  &.icon-on_time {
    background: url('/assets/icons/status-on-time.svg') no-repeat;
  }

  &.icon-early {
    background: url('/assets/icons/status-early.svg') no-repeat;
  }

  &.icon-late {
    background: url('/assets/icons/status-late.svg') no-repeat;
  }
}

.transit-account-badge {
  @include rounded-corner(4px);

  height: 18px;
  padding: 0 4px;
  background-color: var(--wc-grey-3-to-greyscale-7);
  color: $dark-grey-6;
  font-size: 11px;
  font-family: $primary-font-family;
  line-height: 18px;

  .fa-briefcase {
    margin-right: 2px;
    color: $dark-grey-6;
    font-size: 12px;
  }
}

.transit-my-agency-badge {
  @include rounded-corner(4px);

  height: 18px;
  padding: 0 4px;
  background-color: var(--wc-light-blue-6-to-light-blue-3-8);
  color: var(--wc-white-to-black-alpha-7);
  font-size: 11px;
  font-family: $primary-font-family;
  line-height: 18px;

  .fa-briefcase {
    margin-right: 5px;
  }
}

.weather-icon {
  justify-self: center;
  color: $light-blue-4;

  // &.icon-flood,
  // &.icon-coastal_flood,
  // &.icon-flash_flood,
  // &.icon-areal_flood {
  //   &::after {
  //     content: '\f775';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-tornado,
  // &.icon-tropical_storm,
  // &.icon-hurricane,
  // &.icon-storm_surge,
  // &.icon-tropical_weather,
  // &.icon-typhoon {
  //   &::after {
  //     content: '\f76f';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-excessive_heat {
  //   &::after {
  //     content: '\f76a';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-severe_thunderstorm,
  // &.icon-near_severe_thunderstorm {
  //   &::after {
  //     content: '\f76c';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-winter_storm,
  // &.icon-winter_weather {
  //   &::after {
  //     content: '\f740';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-wind,
  // &.icon-high_wind,
  // &.icon-extreme_wind,
  // &.icon-wind_chill {
  //   &::after {
  //     content: '\f72e';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-freezing_rain,
  // &.icon-freezing_drizzle {
  //   &::after {
  //     content: '\f741';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-sleet,
  // &.icon-heavy_sleet,
  // &.icon-blowing_snow,
  // &.icon-snow,
  // &.icon-heavy_snow,
  // &.icon-snow_squall,
  // &.icon-blizzard,
  // &.icon-ice_storm,
  // &.icon-lake_effect_snow {
  //   &::after {
  //     content: '\f7cf';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-dense_fog,
  // &.icon-freezing_fog {
  //   &::after {
  //     content: '\f74e';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-tsunami,
  // &.icon-hurricane_force_wind {
  //   @extend .waycare-font-icon, .tsunami;
  // }

  // &.icon-dust,
  // &.icon-blowing_dust,
  // &.icon-dust_storm {
  //   @extend .waycare-font-icon, .dust;
  // }

  // &.icon-black_ice,
  // &.icon-snow_on_road,
  // &.icon-ice_on_road {
  //   &::before {
  //     content: '\f7ad';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-fire,
  // &.icon-fire_weather,
  // &.icon-red_flag_fire,
  // &.icon-rangeland_fire_danger {
  //   @extend .waycare-font-icon, .fire;
  // }

  // &.icon-rainfall {
  //   &::before {
  //     content: '\f743';
  //     font-family: $fontawesome-icons;
  //   }
  // }

  // &.icon-rainfall {
  //   &::before {
  //     content: '\f743';
  //     font-family: $fontawesome-icons;
  //   }
  // }
}

.weather-status-icon {
  display: block;
  width: 13px;
  height: 13px;

  &.icon-forecast {
    background: url('/assets/icons/status-inactive.svg') no-repeat;
  }

  &.icon-active {
    background: url('/assets/icons/status-active.svg') no-repeat;
  }
}

.status-text-active {
  color: $green-3;
  font-size: 12px;
}

.status-text-forecast {
  color: var(--wc-deep-blue-2-to-greyscale-5);
  font-size: 12px;
}

.mat-radio-button .mat-radio-ripple {
  width: 0 !important;
  height: 0 !important;
}

.highlighted-card {
  border: 2px solid var(--wc-green-1-to-light-blue-3-4);
  background-color: var(--wc-new-incident-highlight-color);
}

// Unit status
.unit-response-icon {
  @include rounded-corner(50%);

  width: 18px;
  height: 18px;
  margin-right: 9px;
  font-weight: 900;
  font-style: normal;
  font-size: 18px;
  font-family: $fontawesome-icons;
  line-height: 18px;

  .tablet-mode & {
    width: 25px;
    height: 21px;
    margin-right: 29px;
    font-size: 25px;
  }

  &.en_route {
    display: inline-block;
    text-align: center;

    &::after {
      content: '\f602';
      color: #ffad4d;
    }

    &.tablet-mode {
      font-size: 30px;
    }

    .tablet-mode & {
      &::after {
        vertical-align: baseline;
        font-size: 23px;
      }
    }
  }

  &.mitigated {
    background-color: var(--wc-deep-blue-6-to-light-blue-3-8);

    &::after {
      content: '\f058';
      background-color: var(--wc-white-to-dark-greyish-blue-9);
      color: var(--wc-deep-blue-6-to-greyscale-3);
    }
  }

  &.on_scene {
    display: inline-block;
    background-color: $green-4;
    text-align: center;

    &::after {
      content: '\f3c5';
      vertical-align: top;
      color: var(--wc-white-to-black-alpha-7);
      font-size: 10px;
    }

    .tablet-mode & {
      height: 25px;

      &::after {
        vertical-align: baseline;
        font-size: 14px;
      }
    }
  }

  &.on_active {
    display: inline-block;
    background-color: $green-4;
    text-align: center;

    &::after {
      content: '\f110';
      vertical-align: top;
      color: #ffff;
      font-size: 10px;
    }

    .tablet-mode & {
      height: 25px;

      &::after {
        vertical-align: baseline;
        font-size: 14px;
      }
    }
  }

  &.unknown_unit_status {
    &::after {
      content: '\f059';
      background-color: var(--wc-white-to-dark-greyish-blue-9);
      color: $grey-6;
    }
  }
}

.share-button {
  color: var(--wc-greyish-blue-5-to-white-alpha-7);
  font-size: 14px;
  font-family: $primary-font-family-bold;

  .fas {
    margin-right: 6px;
    font-size: 18px;
  }

  &:hover {
    color: var(--wc-deep-blue-6-to-greyscale-3);
    cursor: pointer;
  }
}

.right-menu-icon-button {
  color: var(--wc-black-to-white-alpha-7) !important;
}

// lane type icons
.lane-icon {
  &.icon-gore {
    background: url('/assets/icons/lane-types/gore.svg') no-repeat;

    .dark-theme & {
      background: url('/assets/icons-dark/lane-types/gore.svg') no-repeat;
    }
  }

  &.icon-lane {
    background: url('/assets/icons/lane-types/lane.svg') no-repeat;

    .dark-theme & {
      background: url('/assets/icons-dark/lane-types/lane.svg') no-repeat;
    }
  }

  &.icon-left-shoulder {
    background: url('/assets/icons/lane-types/left-shoulder.svg') no-repeat;

    .dark-theme & {
      background: url('/assets/icons-dark/lane-types/left-shoulder.svg') no-repeat;
    }
  }

  &.icon-right-shoulder {
    background: url('/assets/icons/lane-types/right-shoulder.svg') no-repeat;

    .dark-theme & {
      background: url('/assets/icons-dark/lane-types/right-shoulder.svg') no-repeat;
    }
  }
}

/* Empty State Icons */
.empty-incident-insights-icon {
  width: 190px;
  height: 122px;
  margin-bottom: 13px;
  background: url('/assets/images/empty-incident-insights.svg') no-repeat;
  background-size: cover;
}

.empty-state-icon-media {
  width: 190px;
  height: 122px;
  margin-bottom: 15px;
  background: url('/assets/images/empty-media-view.svg') no-repeat;
  background-size: cover;
}

.no-lanes-image {
  width: 123px;
  height: 121px;
  background: url('/assets/icons/no-affected-lanes.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 132px;
    height: 131px;
    background-size: cover;
  }
}

.camera-error-icon {
  width: 123px;
  height: 123px;
  background: url('/assets/images/empty-camera-error.svg') no-repeat;
  background-size: cover;
}

.empty-cameras {
  width: 121px;
  height: 121px;
  background: url('/assets/images/empty-cameras.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 189px;
  }
}

.camera-inactive-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: $greyscale-7;
  font-size: 14px;
  font-family: $primary-font-family;
  text-align: center;

  .camera-inactive-icon {
    width: 132px;
    margin: 12px 0 20px;

    .widget & {
      width: 100px;
      margin: 12px 0;
    }
  }

  .camera-error-message {
    font-size: 16px;
    font-family: $primary-font-family-bold;
  }

  .camera-error-action {
    width: 300px;
    margin: 12px auto;
  }

  &.tablet-mode {
    font-size: 20px;
    line-height: 29px;

    .camera-inactive-icon {
      width: 180px;
      margin: 22px 0 36px;
    }

    .camera-error-message {
      font-size: 24px;
    }

    .camera-error-action {
      width: 380px;
    }
  }
}

.camera-section {
  .camera-inactive-container {
    .camera-inactive-icon {
      width: 132px;
      margin: 12px 0 20px;

      .widget & {
        width: 100px;
        margin: 12px 0;
      }
    }
  }
}

.notes-empty-state-icon {
  width: 100px;
  height: 100px;
  background: url('/assets/icons/notes_empty_state.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 170px;
    margin-right: 30px;
    background-size: cover;
  }
}

.empty-state-additional-info {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  background: url('/assets/images/settings-empty-additional-info.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 170px;
    background-size: cover;
  }
}

.empty-related-dms {
  width: 100px;
  height: 100px;
  background: url('/assets/images/empty-related-dms.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 170px;
    background-size: cover;
  }
}

.empty-units-icon {
  width: 170px;
  height: 120px;
  margin-top: 170px;
  margin-bottom: 15px;
  background: url('/assets/images/empty-units.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 140px;
    background-size: cover;
  }
}

.empty-contacts-icon {
  width: 100px;
  height: 100px;
  background: url('/assets/icons/contacts_empty_state.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 170px;
    background-size: cover;
  }
}

.empty-involved-vehicles {
  width: 100px;
  height: 100px;
  background: url('/assets/images/empty-involved-vehicles.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 140px;
    background-size: cover;
  }
}

.description-empty-state-icon {
  width: 100px;
  height: 100px;
  background: url('/assets/icons/description_empty_state.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 170px;
    height: 170px;
    background-size: cover;
  }
}

.map-center-location-icon {
  width: 31px;
  height: 31px;
  margin-right: 9px;
  background: url('/assets/icons/new-map-center.svg') no-repeat;
}

.map-center-location-focus-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: url('/assets/icons/map-center-location-focus-new.svg') no-repeat;
}

.empty-work-request-list-icon {
  width: 140px;
  height: 170px;
  background: url('/assets/images/empty-work-request.svg') no-repeat;

  .tablet-mode & {
    width: 150px;
    height: 170px;
  }
}

.empty-incident-list-icon {
  width: 140px;
  height: 170px;
  background: url('/assets/images/empty-incidents.svg') no-repeat;

  .tablet-mode & {
    width: 150px;
    height: 170px;
  }
}

.no-need-actions-events-icon {
  width: 170px;
  height: 170px;
  margin-left: 30px;
  background: url('/assets/images/empty-need-action-events.svg') no-repeat;

  .tablet-mode & {
    width: 170px;
    height: 170px;
  }
}

.no-workspaces-selected-icon {
  width: 170px;
  height: 170px;
  margin-left: 17px;
  background: url('/assets/images/no-workspaces.svg') no-repeat;

  .tablet-mode & {
    width: 170px;
    height: 170px;
  }
}

.empty-completed-events-icon {
  width: 140px;
  height: 170px;
  background: url('/assets/images/empty-completed-events.svg') no-repeat;

  .tablet-mode & {
    width: 150px;
    height: 170px;
  }
}

.empty-attributes {
  width: 160px;
  height: 120px;
  margin: 17px 0;
  background: url('/assets/images/empty-attributes.svg') no-repeat;
  background-size: cover;

  .tablet-mode & {
    width: 236px;
    height: 170px;
  }
}

.empty-mitigation-icon {
  width: 155px;
  height: 140px;
  background: url('/assets/images/empty-mitigation.svg') no-repeat;

  .tablet-mode & {
    width: 150px;
    height: 170px;
  }
}

.empty-response-plan-icon {
  width: 130px;
  height: 100px;
  background: url('/assets/icons/response_plan_empty_state.svg') no-repeat;

  .tablet-mode & {
    width: 150px;
    height: 170px;
  }
}

.empty-media-create-icon {
  width: 147px;
  height: 100px;
  background: url('/assets/images/empty-media-create.svg') no-repeat;
  background-size: contain;

  .tablet-mode & {
    width: 190px;
    height: 127px;
    margin-top: 65px;
  }
}

.empty-transit-affected-service {
  width: 82px;
  height: 75px;
  background: url('/assets/images/empty-transit-affected-service.svg') no-repeat;
  background-size: contain;
}

.phone-unit-icon {
  background: url('/assets/icons/unit-phone.svg') no-repeat;
  background-size: cover;
}

.incident-form-united__icon {
  width: 98px;
  height: 99px;
  margin-bottom: 30px;
  background: url('/assets/icons/incident-form-united.svg') no-repeat;
  background-size: cover;
}

.clock-rotate-right-icon {
  width: 13px;
  height: 13px;
  background: url('/assets/icons/clock-rotate-right.svg') no-repeat;
  background-size: cover;
}

/* Dark Icons */
.dark-theme {
  .incident-form-united__icon {
    background: url('/assets/icons-dark/incident-form-united.svg') no-repeat;
  }

  .phone-unit-icon {
    background: url('/assets/icons-dark/unit-phone.svg') no-repeat;
    background-size: cover;
  }

  .icon-details {
    background: url('/assets/icons-dark/details.svg') no-repeat;
    background-size: cover;
  }

  .icon-edit {
    background: url('/assets/icons-dark/edit-white.svg') no-repeat;
    background-size: cover;
  }

  .icon-remove {
    background: url('/assets/icons-dark/trash-bin-white.svg') no-repeat;
    background-size: cover;
  }

  .icon-close {
    background: url('/assets/icons-dark/close-white.svg') no-repeat;
    background-size: cover;
  }

  .icon-attention {
    background: url('/assets/icons-dark/attention.svg') no-repeat;
    background-size: cover;

    &.icon-white-color {
      background: url('/assets/icons-dark/attention-white.svg') no-repeat;
      background-size: cover;
    }
  }

  .sound-alert-bell {
    background: url('/assets/icons-dark/bell.svg') no-repeat;
    background-size: cover;
  }

  .no-workspaces-selected-icon {
    width: 128px;
    height: 128px;
    margin-bottom: 20px;
    background: url('/assets/icons-dark/no-workspaces.svg') no-repeat;
    background-size: cover;
  }

  .incidents-panel {
    .icon-close {
      background: url('/assets/icons-dark/close-dark.png') no-repeat;
      background-size: cover;
    }
  }

  .primary-button {
    text-align: center;

    &.button-arrow-right {
      &::after {
        background-image: url('/assets/icons-dark/button-arrow-dark.svg');
        background-size: cover;
        transform: rotate(180deg);
      }
    }
  }

  .secondary-button {
    text-align: center;

    &.button-arrow-left {
      &::before {
        background-image: url('/assets/icons-dark/button-arrow-dark.svg');
        background-size: cover;
      }

      &:disabled {
        &::before {
          background-image: url('/assets/icons-dark/button-arrow-dark.svg');
          background-size: cover;
        }
      }
    }

    &.button-arrow-right {
      &::after {
        background-image: url('/assets/icons-dark/button-arrow-dark.svg');
        background-size: cover;
        transform: rotate(180deg);
      }

      &:disabled {
        &::after {
          background-image: url('/assets/icons-dark/button-arrow-dark.svg');
          background-size: cover;
        }
      }
    }
  }

  .map-button-wrapper {
    .map-button-image {
      &.button-satellite {
        background: url('/assets/icons-dark/satellite.svg') no-repeat;
        background-size: cover;
      }

      &.button-traffic {
        background: url('/assets/icons-dark/traffic.svg') no-repeat;
        background-size: cover;
      }

      &.button-mile-markers {
        background: url('/assets/icons-dark/mile_markers.svg') no-repeat;
        background-size: cover;
      }
    }

    &.active {
      .button-satellite {
        &::before {
          background: url('/assets/icons-dark/selected-layer.svg') no-repeat;
          background-color: $white;
          background-size: cover;
        }
      }

      .button-traffic {
        &::before {
          background: url('/assets/icons-dark/selected-layer.svg') no-repeat;
          background-color: $white;
          background-size: cover;
        }
      }

      .button-mile-markers {
        &::before {
          background: url('/assets/icons-dark/selected-layer.svg') no-repeat;
          background-color: $white;
          background-size: cover;
        }
      }
    }
  }

  // Incidents
  .incident-type-icon {
    &.list-icon-crash {
      background: url('/assets/icons-dark/list_crash.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-crash-unconfirmed {
      background: url('/assets/icons-dark/list_crash.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-debris {
      background: url('/assets/icons-dark/list_debris.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-debris-unconfirmed {
      background: url('/assets/icons-dark/list_debris.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-abandoned_vehicle {
      background: url('/assets/icons-dark/list_abandoned_vehicle.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-abandoned_vehicle-unconfirmed {
      background: url('/assets/icons-dark/list_abandoned_vehicle.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-stalled_vehicle {
      background: url('/assets/icons-dark/list_disabled_vehicle.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-stalled_vehicle-unconfirmed {
      background: url('/assets/icons-dark/list_disabled_vehicle.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-vehicle_on_fire {
      background: url('/assets/icons-dark/list_vehicle_on_fire.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-vehicle_on_fire-unconfirmed {
      background: url('/assets/icons-dark/list_vehicle_on_fire.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-left_on_arrival {
      background: url('/assets/icons-dark/list_left_on_arrival.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-left_on_arrival-unconfirmed {
      background: url('/assets/icons-dark/list_left_on_arrival.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-ems {
      background: url('/assets/icons-dark/list_ems.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-ems-unconfirmed {
      background: url('/assets/icons-dark/list_ems.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-police_activity {
      background: url('/assets/icons-dark/list_police_activity.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-police_activity-unconfirmed {
      background: url('/assets/icons-dark/list_police_activity.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-hazard {
      background: url('/assets/icons-dark/list_hazard.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-hazard-unconfirmed {
      background: url('/assets/icons-dark/list_hazard.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-damage {
      background: url('/assets/icons-dark/list_damage.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-damage-unconfirmed {
      background: url('/assets/icons-dark/list_damage.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-traffic_stop {
      background: url('/assets/icons-dark/list_traffic_stop.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-traffic_stop-unconfirmed {
      background: url('/assets/icons-dark/list_traffic_stop.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-other {
      background: url('/assets/icons-dark/list_other.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-other-unconfirmed {
      background: url('/assets/icons-dark/list_other.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-unidentified,
    &.list-icon-traffic_anomaly {
      background: url('/assets/icons-dark/list_unknown.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-unidentified-unconfirmed,
    &.list-icon-traffic_anomaly-unconfirmed {
      background: url('/assets/icons-dark/list_unknown.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-road_closure {
      background: url('/assets/icons-dark/list_road_closure.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-road_closure-unconfirmed {
      background: url('/assets/icons-dark/list_road_closure.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-special_event {
      background: url('/assets/icons-dark/list_event.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-special_event-unconfirmed {
      background: url('/assets/icons-dark/list_event.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-construction {
      background: url('/assets/icons-dark/list_construction.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-construction-unconfirmed {
      background: url('/assets/icons-dark/list_construction.unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-wrong_way {
      background: url('/assets/icons-dark/list_wrong_way.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-wrong_way-unconfirmed {
      background: url('/assets/icons-dark/list_wrong_way.unconfirmed.svg') no-repeat;
      background-size: cover;
    }
  }

  .incident-type-icon-grey {
    &.list-icon-crash {
      background: url('/assets/icons-dark/list_crash_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-debris {
      background: url('/assets/icons-dark/list_debris_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-abandoned_vehicle {
      background: url('/assets/icons-dark/list_abandoned_vehicle_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-stalled_vehicle {
      background: url('/assets/icons-dark/list_disabled_vehicle_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-vehicle_on_fire {
      background: url('/assets/icons-dark/list_vehicle_on_fire_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-left_on_arrival {
      background: url('/assets/icons-dark/list_left_on_arrival_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-ems {
      background: url('/assets/icons-dark/list_ems_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-police_activity {
      background: url('/assets/icons-dark/list_police_activity_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-hazard {
      background: url('/assets/icons-dark/list_hazard_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-damage {
      background: url('/assets/icons-dark/list_damage_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-other {
      background: url('/assets/icons-dark/list_other_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-unidentified,
    &.list-icon-traffic_anomaly {
      background: url('/assets/icons-dark/list_other_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-road_closure {
      background: url('/assets/icons-dark/list_road_closure_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-special_event {
      background: url('/assets/icons-dark/list_event_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-construction {
      background: url('/assets/icons-dark/list_construction_grey.svg') no-repeat;
      background-size: cover;
    }

    &.list-icon-traffic_stop {
      background: url('/assets/icons-dark/list_traffic_stop_grey.svg') no-repeat;
      background-size: cover;
    }
  }

  .incident-status-icon {
    &.icon-unconfirmed {
      background: url('/assets/icons-dark/status-unconfirmed.svg') no-repeat;
      background-size: cover;
    }

    &.icon-confirmed {
      background: url('/assets/icons-dark/status-confirmed.svg') no-repeat;
      background-size: cover;
    }

    &.icon-completed {
      background: url('/assets/icons-dark/status-completed.svg') no-repeat;
      background-size: cover;
    }

    &.icon-rejected {
      background: url('/assets/icons-dark/status-rejected.svg') no-repeat;
      background-size: cover;
    }
  }

  .work-req-status-icon {
    &.icon-inactive {
      background: url('/assets/icons-dark/status-inactive.svg') no-repeat;
      background-size: cover;
    }

    &.icon-completed {
      background: url('/assets/icons-dark/status-completed.svg') no-repeat;
      background-size: cover;
    }

    &.icon-active {
      background: url('/assets/icons-dark/status-active.svg') no-repeat;
      background-size: cover;
    }
  }

  .weather-status-icon {
    &.icon-forecast {
      background: url('/assets/icons-dark/status-inactive.svg') no-repeat;
      background-size: cover;
    }

    &.icon-active {
      background: url('/assets/icons-dark/status-active.svg') no-repeat;
      background-size: cover;
    }
  }

  .empty-state-icon-media {
    background: url('/assets/images-dark/empty-media-view.svg') no-repeat;
    background-size: cover;
  }

  .no-lanes-image {
    background: url('/assets/icons-dark/no-affected-lanes.svg') no-repeat;
    background-size: cover;
  }

  .camera-error-icon {
    background: url('/assets/images-dark/empty-camera-error.svg') no-repeat;
    background-size: cover;
  }

  .empty-cameras {
    background: url('/assets/images-dark/empty-cameras.svg') no-repeat;
    background-size: cover;
  }

  .notes-empty-state-icon {
    background: url('/assets/icons-dark/notes_empty_state.svg') no-repeat;
    background-size: cover;
  }

  .empty-state-additional-info {
    background: url('/assets/images-dark/settings-empty-additional-info.svg') no-repeat;
    background-size: cover;
  }

  .empty-units-icon {
    background: url('/assets/images-dark/empty-units.svg') no-repeat;
    background-size: cover;
  }

  .empty-related-dms {
    background: url('/assets/images-dark/empty-related-dms.svg') no-repeat;
    background-size: cover;
  }

  .empty-contacts-icon {
    background: url('/assets/icons-dark/contacts_empty_state.svg') no-repeat;
    background-size: cover;
  }

  .empty-involved-vehicles {
    background: url('/assets/images-dark/empty-involved-vehicles.svg') no-repeat;
    background-size: cover;
  }

  .empty-work-request-list-icon {
    background: url('/assets/images-dark/empty-work-request.svg') no-repeat;
  }

  .empty-incident-list-icon {
    background: url('/assets/images-dark/empty-incidents.svg') no-repeat;
  }

  .no-need-actions-events-icon {
    background: url('/assets/images-dark/empty-need-action-events.svg') no-repeat;
  }

  .empty-completed-events-icon {
    background: url('/assets/images-dark/empty-completed-events.svg') no-repeat;
  }

  .empty-mitigation-icon {
    background: url('/assets/images-dark/empty-mitigation.svg') no-repeat;
  }

  .empty-media-create-icon {
    background: url('/assets/images-dark/empty-media-create.svg') no-repeat;
    background-size: contain;
  }

  .description-empty-state-icon {
    background: url('/assets/icons-dark/description_empty_state.svg') no-repeat;
    background-size: cover;
  }
}

.settings-component-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 32px 35px 32px 3.2%;
  border-bottom: 1px solid $grey-5;
  font-size: 27px;
}

wc-incidents-list {
  position: relative;
}

.date-time-title {
  font-family: $primary-font-family-bold;
}

.red-asterisk {
  color: red;
  font-size: 14px;
  line-height: 10px;
}

wc-segment-details-card {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.portrait-mode {
  .rf-map-layers-panel {
    .rf-map-layers-panel__wrapper {
      max-height: calc(
        50vh - #{$main-nav-header-height} - #{$map-layers-panel-button} - #{$map-data-layers-buttons-height} - 20px
      );
    }
  }

  .rf-crash-risk-panel {
    .rf-crash-risk-panel__body {
      max-height: calc(
        50vh - #{$main-nav-header-height} - #{$map-layers-panel-button} - #{$map-data-layers-buttons-height} - 196px
      );
    }
  }

  .segments-list-panel {
    .segments-list-panel__body {
      max-height: calc(
        50vh - #{$main-nav-header-height} - #{$map-layers-panel-button} - #{$map-data-layers-buttons-height} - 220px
      );
    }
  }

  .transit-panel-tab-content {
    .transit-panel-body {
      max-height: calc(
        50vh - #{$main-nav-header-height} - #{$map-layers-panel-button} - #{$map-data-layers-buttons-height} - 216px
      );
    }

    .transit-secondary-panel {
      max-height: calc(
        50vh - #{$main-nav-header-height} - #{$map-layers-panel-button} - #{$map-data-layers-buttons-height} - 120px
      );
    }
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  color: var(--wc-greyscale-7-to-white-alpha-7);
  font-family: $primary-font-family-bold;
  text-align: center;

  .language-rtl & {
    direction: rtl;
  }

  .tablet-mode & {
    font-size: 24px;
  }

  .widget & {
    font-size: 13px;
  }

  .empty-state-message {
    padding-bottom: 5px;
    font-size: 16px;
    font-family: $primary-font-family-bold;
  }

  .empty-state-action {
    font-family: $primary-font-family;
  }

  .empty-state-icon {
    width: 100px;
    margin-right: 10px;

    .tablet-mode & {
      width: 200px;
    }
  }
}

.icon-last-update {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url('/assets/icons/last-update.svg') no-repeat;
  background-size: cover;
}

.settings-page-header {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 60px;
  border-bottom: 1px solid $grey-5;

  &__main {
    padding-bottom: 13px;
    color: $deep-blue-3;
    font-size: 12px;
    font-family: $primary-font-family-bold;
  }

  &__section {
    font-size: 25px;
  }

  button {
    border: none;
    cursor: pointer;
  }

  &__button-group {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
}
