/* stylelint-disable declaration-no-important */

/* Media Query Mixin */
@mixin screen($size, $type: min, $pixels: $breakpoint-sm) {
  @if $size == 'xs' {
    @media screen and (#{$type + '-width'}: $breakpoint-xs) {
      @content;
    }
  } @else if $size == 'sm' {
    // 576px (2560 x 1600 - Samsung Galaxy Tab S5e, used by Louisiana [View mode: 712 x 1138])
    @media screen and (#{$type + '-width'}: $breakpoint-sm) {
      @content;
    }
  } @else if $size == 'md' {
    // 768px (768 x 1024 - iPad, iPad Air portrait mode)
    @media screen and (#{$type + '-width'}: $breakpoint-md) {
      @content;
    }
  } @else if $size == 'lg' {
    // 992px (1024 x 768 - iPad, iPad Air landscape mode, 1024 x 1366 - iPad Pro portrait mode)
    @media screen and (#{$type + '-width'}: $breakpoint-lg) {
      @content;
    }
  } @else if $size == 'xl' {
    // 1200px (1366 x 1024 - iPad Pro landscape mode)
    @media screen and (#{$type + '-width'}: $breakpoint-xl) {
      @content;
    }
  } @else if $size == 'fhd' {
    @media screen and (#{$type + '-width'}: $breakpoint-fhd) {
      @content;
    }
  } @else if $size == 'uhd' {
    @media screen and (#{$type + '-width'}: $breakpoint-uhd) {
      @content;
    }
  } @else if $size == 'custom' {
    @media screen and (#{$type + '-width'}: #{$pixels + px}) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Border Radius Mixin */
@mixin rounded-corner($rad) {
  -moz-border-radius: $rad;
  -webkit-border-radius: $rad;
  border-radius: $rad;
}

/**
* FLEXBOX
* A mixin to rule complex flexbox layout
* @author nicolacastellanidev@gmail.com
*/
@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }

    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }

    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  display: $display;
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

/**
* Shorthand mixin for offset positioning
* @param {String} $position - Either `relative`, `absolute` or `fixed`
* @param {Length} $top [null] - Top offset
* @param {Length} $right [null] - Right offset
* @param {Length} $bottom [null] - Bottom offset
* @param {Length} $left [null] - Left offset
*/
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/**
* Position absolute mixin
*/
@mixin absolute($zIndex: null, $args...) {
  @include position(absolute, $args...);

  z-index: $zIndex;
}

/**
* Position relative mixin
*/
@mixin relative($args...) {
  @include position(relative, $args...);
}

/**
* Position fixed mixin
*/
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin fontAwesomeIcon($content, $size: inherit, $font-weight: 600) {
  font-weight: $font-weight;
  font-style: normal;
  font-size: $size;

  &::before {
    content: $content;
    font-family: $fontawesome-icons;

    .tablet-mode & {
      font-size: 22px;
    }
  }
}

@mixin waycareFontIcon($content, $size: inherit) {
  font-weight: 400;
  font-style: normal;
  font-size: $size;

  &::before {
    content: $content;
    font-family: $waycare-font-icons;

    .tablet-mode & {
      font-size: 22px;
    }
  }
}

@function calcfontsize($family, $size: 20px) {
  @if $family == $waycare-font-icons {
    @return $size + 5px;
  }

  @return $size;
}

@mixin generalIcon($family, $content, $size: $base-icon-size) {
  font-weight: 600;
  font-style: normal;
  font-size: calcfontsize($family, $base-icon-size);

  &::after {
    content: $content;
    font-family: $family;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin focus-styles($outlineOffset) {
  @include rounded-corner(6px);

  outline: 2px solid $light-blue-8 !important;
  outline-offset: $outlineOffset;
}
