@mixin theme-colors($dark-theme) {
  //black
  --wc-black-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $black)};
  --wc-black-to-white: #{if($dark-theme, $white, $black)};
  --wc-black-to-white-alpha-5: #{if($dark-theme, $white-alpha-5, $black)};

  //side-panel
  --wc-side-panel-to-greyscale-8: #{if($dark-theme, $greyscale-8, $side-panel)};
  --wc-side-panel-to-dark-greyish-blue-9: #{if($dark-theme, $dark-greyish-blue-9, $side-panel)};

  //light-blue-0
  --wc-light-blue-0-to-light-blue-2-2: #{if($dark-theme, $light-blue-4-2, $light-blue-2)};
  --wc-light-blue-0-to-dark-greyish-blue-3: #{if($dark-theme, $dark-greyish-blue-3, $light-blue-2)};
  --wc-light-blue-0-to-white-1: #{if($dark-theme, $white-1, $light-blue-2)};
  --wc-light-blue-0-to-black-alpha-7: #{if($dark-theme, $black-alpha-7, $light-blue-2)};

  //light-blue-1
  --wc-light-blue-1-to-light-blue-2-2: #{if($dark-theme, $light-blue-4-2, $light-blue-3)};
  --wc-light-blue-1-to-dark-greyish-blue-5: #{if($dark-theme, $dark-greyish-blue-5, $light-blue-3)};
  --wc-light-blue-1-to-dark-greyish-blue-10: #{if($dark-theme, $dark-greyish-blue-10, $light-blue-1)};

  //light-blue-2
  --wc-light-blue-2-to-dark-greyish-blue-10: #{if($dark-theme, $dark-greyish-blue-10, $light-blue-2)};

  //light-blue-5
  --wc-light-blue-5-to-light-blue-2-7: #{if($dark-theme, $light-blue-4-7, $light-blue-7)};
  --wc-light-blue-5-to-light-blue-3-8: #{if($dark-theme, $light-blue-5-8, $light-blue-7)};
  --wc-light-blue-5-to-light-blue-3-6: #{if($dark-theme, $light-blue-5-6, $light-blue-7)};

  //light-blue-6
  --wc-light-blue-6-to-white: #{if($dark-theme, $white, $light-blue-8)};
  --wc-light-blue-6-to-light-blue-2: #{if($dark-theme, $light-blue-4, $light-blue-8)};
  --wc-light-blue-6-to-light-blue-2-8: #{if($dark-theme, $light-blue-4-8, $light-blue-8)};
  --wc-light-blue-6-to-light-blue-3-5: #{if($dark-theme, $light-blue-5-5, $light-blue-8)};
  --wc-light-blue-6-to-light-blue-3-8: #{if($dark-theme, $light-blue-5-8, $light-blue-8)};
  --wc-light-blue-6-to-linear-gradient: #{if($dark-theme, linear-gradient(to right, #2e3d42, #50646b), $light-blue-8)};
  --wc-light-blue-6-to-white-6: #{if($dark-theme, $white-6, $light-blue-6)};

  //light-blue-8
  --wc-light-blue-8-to-light-blue-5: #{if($dark-theme, $light-blue-5, $light-blue-8)};

  //greyish-blue-5
  --wc-greyish-blue-5-to-light-blue-2-2: #{if($dark-theme, $light-blue-4-2, $greyish-blue-5)};

  //deep-blue-6
  --wc-deep-blue-6-to-white: #{if($dark-theme, $white, $deep-blue-6)};
  --wc-deep-blue-6-to-white-1: #{if($dark-theme, $white-1, $deep-blue-6)};
  --wc-deep-blue-6-to-white-7: #{if($dark-theme, $white-7, $deep-blue-6)};
  --wc-deep-blue-6-to-white-6: #{if($dark-theme, $white-6, $deep-blue-6)};
  --wc-deep-blue-6-to-white-9: #{if($dark-theme, $white-9, $deep-blue-6)};
  --wc-deep-blue-6-to-light-blue-2-7: #{if($dark-theme, $light-blue-4-7, $deep-blue-6)};
  --wc-deep-blue-6-to-light-blue-2-8: #{if($dark-theme, $light-blue-4-8, $deep-blue-6)};
  --wc-deep-blue-6-to-light-blue-3-6: #{if($dark-theme, $light-blue-5-6, $deep-blue-6)};
  --wc-deep-blue-6-to-light-blue-3-8: #{if($dark-theme, $light-blue-5-8, $deep-blue-6)};
  --wc-deep-blue-6-to-greyscale-3: #{if($dark-theme, $greyscale-3, $deep-blue-6)};
  --wc-deep-blue-6-to-none: #{if($dark-theme, none, $deep-blue-6)};
  --wc-deep-blue-6-to-dark-greyish-blue-2: #{if($dark-theme, $dark-greyish-blue-2, $deep-blue-6)};

  //deep-blue-5
  --wc-deep-blue-5-to-greyscale-4: #{if($dark-theme, $greyscale-4, $deep-blue-5)};

  //deep-blue-4
  --wc-deep-blue-4-to-greyscale-2: #{if($dark-theme, $greyscale-2, $deep-blue-4)};

  //deep-blue-3
  --wc-deep-blue-3-to-greyscale-4: #{if($dark-theme, $greyscale-4, $deep-blue-3)};

  //deep-blue-2
  --wc-deep-blue-2-to-greyscale-5: #{if($dark-theme, $greyscale-5, $deep-blue-2)};
  --wc-deep-blue-2-to-white-7: #{if($dark-theme, $white-7, $deep-blue-2)};

  //deep-blue-1
  --wc-deep-blue-1-to-greyscale-4: #{if($dark-theme, $greyscale-4, $deep-blue-1)};

  //initial
  --wc-initial-to-greyscale-3: #{if($dark-theme, $greyscale-3, initial)};

  //green-2
  --wc-green-2-to-green-3-8: #{if($dark-theme, $green-3-8, $green-2)};

  //green-3
  --wc-green-3-to-light-blue-3-5: #{if($dark-theme, $light-blue-5-5, $green-3)};

  //green-4
  --wc-green-4-to-green-3: #{if($dark-theme, $green-3, $green-4)};

  //green-5
  --wc-green-5-to-green-3-2: #{if($dark-theme, $green-3-2, $green-5)};

  //green-1
  --wc-green-1-to-green-3-2: #{if($dark-theme, $green-3-2, $green-0)};
  --wc-green-3-to-green-3-8: #{if($dark-theme, $green-3-8, $green-3)};
  --wc-green-1-to-light-blue-3-4: #{if($dark-theme, $light-blue-5-4, $green-1)};
  --wc-green-1-to-green-3: #{if($dark-theme, $green-3, $green-1)};

  //grey-1
  --wc-grey-1-to-dark-greyish-blue-5: #{if($dark-theme, $dark-greyish-blue-5, $grey-1)};
  --wc-grey-1-to-dark-greyish-blue-6: #{if($dark-theme, $dark-greyish-blue-6, $grey-1)};
  --wc-grey-1-to-dark-greyish-blue-9: #{if($dark-theme, $dark-greyish-blue-9, $grey-1)};

  //grey-2
  --wc-grey-2-to-dark-greyish-blue-3: #{if($dark-theme, $dark-greyish-blue-3, $grey-2)};
  --wc-grey-2-to-dark-greyscale-8: #{if($dark-theme, $greyscale-8, $grey-2)};
  --wc-grey-2-to-dark-greyish-blue-10: #{if($dark-theme, $dark-greyish-blue-10, $grey-2)};
  --wc-grey-2-to-white-7: #{if($dark-theme, $white-7, $grey-2)};
  --wc-grey-2-to-white-8: #{if($dark-theme, $white-8, $grey-2)};
  --wc-greyscale-2-to-grey-2: #{if($dark-theme, $grey-2, $greyscale-2)};
  --wc-grey-3-to-dark-greyish-blue-2: #{if($dark-theme, $dark-greyish-blue-2, $grey-3)};

  //grey-3
  --wc-grey-3-to-white-1: #{if($dark-theme, $white-1, $grey-3)};
  --wc-grey-3-to-greyscale-7: #{if($dark-theme, $greyscale-7, $grey-3)};
  --wc-grey-3-to-white-alpha-1: #{if($dark-theme, $white-alpha-1, $grey-3)};
  --wc-dark-grey-3-to-white-5: #{if($dark-theme, $white-5, $dark-grey-3)};
  --wc-grey-3-to-white-5: #{if($dark-theme, $white-5, $grey-3)};
  --wc-grey-3-to-transparent: #{if($dark-theme, transparent, $grey-3)};
  --wc-grey-1-to-greyish-blue-5: #{if($dark-theme, $dark-greyish-blue-5, $dark-grey-1)};

  //grey-4
  --wc-grey-4-to-transparent: #{if($dark-theme, transparent, $grey-4)};
  --wc-grey-4-to-white-alpha-2: #{if($dark-theme, $white-alpha-2, $grey-4)};
  --wc-grey-4-to-white-alpha-5: #{if($dark-theme, $white-alpha-5, $grey-4)};
  --wc-grey-4-to-dark-greyish-blue-10: #{if($dark-theme, $dark-greyish-blue-10, $grey-4)};
  --wc-grey-4-to-black-alpha-5: #{if($dark-theme, $black-alpha-5, $grey-4)};

  //grey-5
  --wc-grey-4-to-white-alpha-4: #{if($dark-theme, $white-alpha-4, $grey-4)};

  //grey-6
  --wc-grey-6-to-dark-greyish-blue-6: #{if($dark-theme, $dark-greyish-blue-6, $grey-6)};
  --wc-grey-6-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $grey-6)};
  --wc-dark-grey-6-to-white-6: #{if($dark-theme, $white-6, $dark-grey-6)};

  //box shadow
  --wc-box-shadow-main-left: #{if($dark-theme, 0 0 15px $black-alpha-5, 0 0 15px rgba($deep-blue-6, 10%))};
  --wc-box-shadow-main-bottom: #{if($dark-theme, 0 4px 15px $black-alpha-5, 0 10px 10px rgba($deep-blue-6, 10%))};
  --wc-box-shadow-main-center: #{if($dark-theme, 0 4px 12px $black-alpha-6, 0 4px 12px rgba($deep-blue-6, 15%))};
  --wc-box-shadow-main-large: #{if($dark-theme, 0 0 44px $black-alpha-5, 0 0 44px rgba($deep-blue-6, 12%))};
  --wc-box-shadow-mat-select-panel: #{if($dark-theme, 0 4px 20px $black-alpha-7, 0 4px 12px rgb(15 24 50 / 15%))};
  --wc-map-tooltip-box-shadow-color: #{if($dark-theme, rgba(0 0 0 / 30%), rgba(15, 24, 50, 10%))};
  --wc-box-shadow-map-search-component: #{if($dark-theme, 4px 4px 25px $black-alpha-5, 4px 4px 25px rgb(15 24 50 / 25%))};
  --wc-box-shadow-affected-lanes-slider-lane: #{if(
      $dark-theme,
      0 1px 5px $black-alpha-4,
      0 1px 5px rgba($deep-blue-6, 8%)
    )};
  --wc-box-shadow-toaster: #{if($dark-theme, 0px 5px 5px rgba(15, 24, 50, 20%), 0 0 15px rgb(15 24 50 / 25%))};

  //borders
  --wc-border-map-search-component: #{if($dark-theme, 1px solid $white-1, none)};

  //white-alpha
  --wc-white-alpha-4-to-black-alpha-4: #{if($dark-theme, $black-alpha-4, $white-alpha-4)};
  --wc-white-alpha-5-to-black-alpha-5: #{if($dark-theme, $black-alpha-5, $white-alpha-5)};
  --wc-white-alpha-6-to-black-alpha-6: #{if($dark-theme, $black-alpha-6, $white-alpha-6)};
  --wc-white-alpha-8-to-black-alpha-8: #{if($dark-theme, $black-alpha-8, $white-alpha-8)};
  --wc-white-alpha-9-to-black-alpha-9: #{if($dark-theme, $black-alpha-9, $white-alpha-9)};
  --wc-white-alpha-4-to-white-alpha-4: #{if($dark-theme, $white-alpha-4, $white-alpha-1)};
  --wc-white-alpha-4-to-transparent: #{if($dark-theme, transparent, $white-alpha-4)};

  //black-alpha
  --wc-black-alpha-5-to-white-alpha-5: #{if($dark-theme, $white-alpha-5, $black-alpha-5)};
  --wc-black-alpha-7-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $black-alpha-7)};
  --wc-black-alpha-8-to-white-alpha-8: #{if($dark-theme, $white-alpha-8, $black-alpha-8)};
  --wc-black-alpha-9-to-white-alpha-9: #{if($dark-theme, $white-alpha-9, $black-alpha-9)};
  --wc-black-alpha-1-half-to-white-alpha-1-half: #{if($dark-theme, $white-alpha-1-half, $black-alpha-1-half)};

  //greyish-blue-3
  --wc-greyish-blue-3-to-light-blue-3-5: #{if($dark-theme, $light-blue-5-5, $greyish-blue-3)};

  //greyish-blue-4
  --wc-greyish-blue-4-to-light-blue-3-5: #{if($dark-theme, $light-blue-5-5, $greyish-blue-4)};
  --wc-greyish-blue-4-to-greyscale-4: #{if($dark-theme, $greyscale-4, $greyish-blue-4)};
  --wc-greyish-blue-4-to-white: #{if($dark-theme, $white, $greyish-blue-4)};

  //greyish-blue-5
  --wc-greyish-blue-5-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $greyish-blue-5)};
  --wc-greyish-blue-5-to-light-blue-2-8: #{if($dark-theme, $light-blue-4-8, $greyish-blue-5)};
  --wc-greyish-blue-5-to-white-alpha-9: #{if($dark-theme, $white-alpha-9, $greyish-blue-5)};
  --wc-greyish-blue-5-to-light-blue-3-8: #{if($dark-theme, $light-blue-5-8, $greyish-blue-5)};
  --wc-greyish-blue-5-to-red-5: #{if($dark-theme, $red-5, $greyish-blue-5)};
  --wc-greyish-blue-5-to-red-4: #{if($dark-theme, $red-4, $greyish-blue-5)};

  //greyish-blue-2
  --wc-greyish-blue-2-to-light-blue-3-4: #{if($dark-theme, $light-blue-5-4, $greyish-blue-2)};

  //disabled
  --wc-disabled-to-white-alpha-2: #{if($dark-theme, $white-alpha-2, $disabled)};

  //white
  --wc-white-to-dark-greyish-blue-4: #{if($dark-theme, $dark-greyish-blue-4, $white)};
  --wc-white-to-dark-greyish-blue-9: #{if($dark-theme, $dark-greyish-blue-9, $white)};
  --wc-white-to-dark-greyish-blue-8: #{if($dark-theme, $dark-greyish-blue-8, $white)};
  --wc-white-to-white-5: #{if($dark-theme, $white-5, $white)};
  --wc-white-to-greyscale-8: #{if($dark-theme, $greyscale-8, $white)};
  --wc-white-to-light-blue-2-2: #{if($dark-theme, $light-blue-4-2, $white)};
  --wc-white-to-white-4: #{if($dark-theme, $white-4, $white)};
  --wc-white-to-light-blue-2-8: #{if($dark-theme, $light-blue-4-8, $white)};
  --wc-white-to-white-alpha-1: #{if($dark-theme, $white-alpha-1, $white)};
  --wc-white-to-dark-greyish-blue-5: #{if($dark-theme, $dark-greyish-blue-5, $white)};
  --wc-white-to-black-alpha-7: #{if($dark-theme, $black-alpha-7, $white)};
  --wc-white-font-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $white)};
  --wc-white-to-white-alpha-1-half: #{if($dark-theme, $white-alpha-1-half, $white)};
  --wc-white-to-white-6: #{if($dark-theme, $white-6, $white)};
  --wc-white-to-white-8: #{if($dark-theme, $white-8, $white)};
  --wc-white-to-white-7: #{if($dark-theme, $white-7, $white)};
  --wc-white-to-light-blue-3-8: #{if($dark-theme, $light-blue-5-8, $white)};
  --wc-white-to-white-2: #{if($dark-theme, $white-2, $white)};
  --wc-white-to-dark-greyish-6: #{if($dark-theme, $dark-greyish-blue-6, $white)};
  --wc-white-to-white-1: #{if($dark-theme, $white-1, $white)};
  --wc-white-to-white-9: #{if($dark-theme, $white-9, $white)};

  //greyscale
  --wc-greyscale-2-to-white-alpha-2: #{if($dark-theme, $white-alpha-2, $greyscale-2)};
  --wc-greyscale-2-to-white-2: #{if($dark-theme, $white-2, $greyscale-2)};
  --wc-greyscale-4-to-white-4: #{if($dark-theme, $white-4, $greyscale-4)};
  --wc-greyscale-1-to-black-alpha-5: #{if($dark-theme, $black-alpha-5, $greyscale-1)};
  --wc-greyscale-3-to-greyscale-7: #{if($dark-theme, $greyscale-7, $greyscale-3)};
  --wc-greyscale-6-to-greyscale-4: #{if($dark-theme, $greyscale-4, $greyscale-6)};
  --wc-greyscale-7-to-white-alpha-7: #{if($dark-theme, $white-alpha-7, $greyscale-7)};
  --wc-greyscale-7-to-white-alpha-8: #{if($dark-theme, $white-alpha-8, $greyscale-7)};
  --wc-greyscale-8-to-white-alpha-8: #{if($dark-theme, $white-alpha-8, $greyscale-8)};
  --wc-greyscale-5-to-greyscale-3: #{if($dark-theme, $greyscale-3, $greyscale-5)};
  --wc-greyscale-7-to-dark-greyish-blue-8: #{if($dark-theme, $dark-greyish-blue-8, $greyscale-7)};

  //red
  --wc-red-5-to-red-3-9: #{if($dark-theme, $red-3-9, $red-5)};
  --wc-red-1-to-red-4-7: #{if($dark-theme, $red-4-7, $red-1)};
  --wc-red-3-to-red-4-7: #{if($dark-theme, $red-4-7, $red-3)};
  --wc-red-0-to-red-4-7: #{if($dark-theme, $red-4-7, $red-0)};

  //orange
  --wc-orange-2-to-light-orange-alpha-2: #{if($dark-theme, $light-orange-alpha-2, $orange-2)};
  --wc-orange-6-to-orange-5: #{if($dark-theme, $orange-5, $orange-6)};
  --wc-orange-1-to-orange-5-alpha-6: #{if($dark-theme, $orange-5-alpha-6, $orange-1)};

  //yellow
  --wc-yellow-0-to-yellow-5-1: #{if($dark-theme, $yellow-5-1, $yellow-0)};
  --wc-yellow-4-to-yellow-0: #{if($dark-theme, $yellow-0, $yellow-4)};

  //incident highlight
  --wc-new-incident-highlight-color: #{if($dark-theme, $dark-greyish-blue-6, $new-incident-card-background)};

  //opacity
  --wc-opacity-100-to-70: #{if($dark-theme, 0.7, 1)};
}

:root {
  @include theme-colors(false);
}

.dark-theme {
  @include theme-colors(true);
}
