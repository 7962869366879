.road-closure-form,
.construction-form {
  .media-tab-group {
    .mat-tab-labels {
      padding: 0;
    }

    .mat-tab-body-content {
      height: 270px;
      padding: 16px 8px 0 0;
    }

    &.tab-long-content {
      .mat-tab-body-content {
        height: 508px;
      }
    }
  }
}
