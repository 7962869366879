/* ==============================================================
 For Laptop & above all (1650px)
 ============================================================== */

@include screen(custom, 1650px) {
  .main-container.boxed {
    max-width: 1920px;
    margin: 0 auto;
  }
}

/* ==============================================================
 For Laptop & above all (1370px)
 ============================================================== */

@include screen(xl, max) {
  .main-container.boxed {
    max-width: 1300px;
    margin: 0 auto;
  }
}

/* -- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@include screen(xs) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
    padding: 0 15px;

    .mat-card {
      flex: 1 0 0%;
      margin: 15px 0;
      border-left: 1px solid $greyscale-5;

      &:first-child {
        border: 0;
      }
    }
  }

  .navbar-header {
    //width: 225px;
    flex-shrink: 0;

    .navbar-brand {
      padding-top: 0;
    }
  }
}

@include screen(xxs, max) {
  .card-group {
    margin: 15px 0;

    .mat-card {
      margin: 0 15px;
      border-top: 1px solid $greyscale-5;

      &:first-child {
        border: 0;
      }
    }
  }
}

@media (orientation: landscape) {
  .hide-in-landscape {
    display: none;
  }

  .full-width-in-landscape {
    width: 100%;
  }
}

@media (orientation: portrait) {
  .hide-in-portrait {
    display: none;
  }
}
