.preloader {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: var(--wc-white-to-dark-greyish-blue-9);
}

.spinner {
  position: relative;
  top: 35%;
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  @include rounded-corner(50%);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1976d2;
  opacity: 0.6;
  animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    transform: scale(0);
  }

  50% {
    transform: scale(1);
    transform: scale(1);
  }
}
