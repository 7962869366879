@import '~ng-pick-datetime/assets/style/picker.min.css';

.owl-dt-container {
  background-color: var(--wc-white-to-dark-greyish-6);
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
  color: var(--wc-greyscale-8-to-white-alpha-8);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  cursor: default;
  pointer-events: none;
}
