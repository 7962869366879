/* Light palette */

/* Red */
$red-0: #f9e5e7;
$red-1: #ffa2a6;
$red-2: #ff8f95;
$red-3: #fe767d;
$red-4: #ff5c65;
$red-5: #ff434c;
$red-6: #fe1b26;
$red-7: #ee000c;

/* Dark Red */
$dark-red-1: #d9161f;
$dark-red-2: #bf121a;
$dark-red-3: #a90a12;
$dark-red-4: #8c0208;
$dark-red-5: #7a0207;
$dark-red-6: #690106;

/* Green */
$green-0: #e1f0ec;
$green-1: #bce9db;
$green-2: #9addc9;
$green-3: #67c7aa;
$green-4: #32ba91;
$green-5: #06af7c;
$green-6: #009367;
$green-7: #01a372;
$green-8: #0c7c5b;

/* Grey */
$grey-1: #f8fafb;
$grey-2: #f2f7f9;
$grey-3: #e4edf1;
$grey-4: #dbe5e9;
$grey-5: #c7d7dd;
$grey-6: #b0c1c8;

/* Greylish blue */
$greyish-blue-1: #3270a9;
$greyish-blue-2: #296296;
$greyish-blue-3: #1e5282;
$greyish-blue-4: #1a4873;
$greyish-blue-5: #113c64;
$greyish-blue-6: #0d3153;

/* Light blue - Enforcement */
$light-blue-1: #f9fcff;
$light-blue-2: #f2f7fd;
$light-blue-3: #d4e9ff;
$light-blue-4: #a6d1ff;
$light-blue-5: #6fb4ff;
$light-blue-6: #3090f8;
$light-blue-7: #157cec;
$light-blue-8: #096dd9;

/* Dark grey */
$dark-grey-1: #a3b6be;
$dark-grey-2: #8ba2ab;
$dark-grey-3: #7a929c;
$dark-grey-4: #728892;
$dark-grey-5: #6a7f87;
$dark-grey-6: #647982;

/* Deep blue */
$deep-blue-1: #8a94b0;
$deep-blue-2: #6d7795;
$deep-blue-3: #5d6887;
$deep-blue-4: #4b5573;
$deep-blue-5: #323c59;
$deep-blue-6: #0f1832;

/* Yellow */
$yellow-0: #fff9ea;
$yellow-1: #ffe485;
$yellow-2: #f5d564;
$yellow-3: #f5c825;
$yellow-4: #e9b500;
$yellow-5: #ddad03;
$yellow-6: #ca9f05;

/* Orange */
$orange-1: #fff3e4;
$orange-2: #fee6d0;
$orange-3: #fdcda1;
$orange-4: #fcb573;
$orange-5: #fb9c44;
$orange-6: #fa8315;
$orange-7: #fa8315;
$light-orange: #ffad4d;
$golden-purple-1: #f9f0ff;
$golden-purple-2: #efdbff;
$golden-purple-3: #d3adf7;
$golden-purple-4: #b37feb;
$golden-purple-5: #8d4bdd;
$magenta-1: #fbecf8;
$magenta-6: #c52099;

/* Greyscale */
$greyscale-1: #fafafa;
$greyscale-2: #eaeaea;
$greyscale-3: #cecece;
$greyscale-4: #aeaeae;
$greyscale-5: #979797;
$greyscale-6: #7e7e7e;
$greyscale-7: #535353;
$greyscale-8: #121212;
$greyscale-9: #030303;

/* White */
$white-9: #e7e8e8;
$white-8: #d0d1d1;
$white-7: #b9b9ba;
$white-6: #a1a2a3;
$white-5: #898b8c;
$white-4: #727476;
$white-2: #434548;
$white-1: #2b2e31;

/* Normal Color */
$black: #000;
$white: #fff;
$disabled: #ccc;

/* Alpha */
//white
$white-alpha-1-half: rgba($white, 5%);
$white-alpha-1: rgba($white, 10%);
$white-alpha-2: rgba($white, 20%);
$white-alpha-3: rgba($white, 30%);
$white-alpha-4: rgba($white, 40%);
$white-alpha-5: rgba($white, 50%);
$white-alpha-6: rgba($white, 60%);
$white-alpha-7: rgba($white, 70%);
$white-alpha-8: rgba($white, 80%);
$white-alpha-9: rgba($white, 90%);

//black
$black-alpha-1-half: rgba($black, 5%);
$black-alpha-1: rgba($black, 10%);
$black-alpha-2: rgba($black, 20%);
$black-alpha-3: rgba($black, 30%);
$black-alpha-4: rgba($black, 40%);
$black-alpha-5: rgba($black, 50%);
$black-alpha-6: rgba($black, 60%);
$black-alpha-7: rgba($black, 70%);
$black-alpha-8: rgba($black, 80%);
$black-alpha-9: rgba($black, 90%);

/* Dark palette */
$dark-greyish-blue-1: #3270a9;
$dark-greyish-blue-2: #1f384e;
$dark-greyish-blue-3: #1c2e3f;
$dark-greyish-blue-4: #1a2938;
$dark-greyish-blue-5: #182530;
$dark-greyish-blue-6: #17212a;
$dark-greyish-blue-7: #161d24;
$dark-greyish-blue-8: #151b21;
$dark-greyish-blue-9: #151a20;
$dark-greyish-blue-10: #14191d;

// dark mode light blue palette
// light-blue-2
$light-blue-4-10: #abe9ff;
$light-blue-4-8: #93c8db;
$light-blue-4-7: #7eabbc;
$light-blue-4-4: #68818b;
$light-blue-4-2: #33434d;

// light-blue-3
$light-blue-5-10: #7dddff;
$light-blue-5-8: #68b4d0;
$light-blue-5-6: #528ca0;
$light-blue-5-5: #487788;
$light-blue-5-4: #3f6879;

// green-3
$green-3-8: #579583;
$green-3-6: #4c6e64;
$green-3-2: #42514d;

// red-3
$red-3-9: #e66c72;

// red-4
$red-4-7: #b9484e;
$red-4-3: #764649;

//orange alpha
$orange-5-alpha-6: rgba($orange-5, 0.6);

// light-orange-alpha
$light-orange-alpha-2: rgba($light-orange, 0.2);

//yellow-5
$yellow-5-1: #4c4737;

/* Blue Pastel */
$blue-pastel: #5c97ec;

/* light Blue */
// $light-blue-3: #f2f7fd;
// $light-blue-4: #a6d1ff;
// $light-blue-8: #096dd9;

/* Blue Police */
$blue-police: #376bba;

/* Turquoise */
$turquoise: #68c7cf;

/* Orange */
$orange: #fa8315;

/* Irregular Congestion */
$irregular-congestion: #2c008a;

/* Regular Congestion */
$congestion: #590bff;

/* Transit unit */
$purple-1: #8636a2;
$pink-1: #c883d8;

/* Special Event */
$dark-brown-1: #6a0105;

/* Backgrounds */
$new-incident-card-background: #f5fdff;
$side-panel: #fafeff;
$app-loading-background: $dark-greyish-blue-7;

// Unit mitigation status
$status-green-bg-color: rgba($green-6, 20%);
$status-mitigated-bg-color: rgba($turquoise, 20%);
$status-rejected-bg-color: rgba($red-6, 20%);

// Scrollbar
$scrollbar-bg-color: transparent;
$emergency-light-red: #fee8e9;
$auto-publish-badge-bg: #e5f6fb;

// Unit color
$red-unit: #c94349;
$orange-unit: #e67434;
$green-unit: #23ac62;
$grey-unit: #7e7c69;
$blue-unit: #376bba;

//Insight
$insight-purple: #39299e;
