.address-modal-title {
  margin-bottom: 30px;
}

.tablet-mode {
  .form-grid {
    display: grid;
    grid-template-columns: 46% 24% 24%;
    row-gap: 10px;
    align-items: end;
    justify-content: space-between;
    max-width: initial;
    padding-bottom: 44px;
  }
}

/* Portrait specific styles */
@media (orientation: portrait) {
  wc-address-control {
    margin-bottom: 45px;
  }
}

/* Landscape specific styles */
@media (orientation: landscape) {
  /* Create new component + Edit location modal */

  wc-address-control {
    color: var(--wc-deep-blue-6-to-greyscale-3);
  }

  .tablet-mode {
    &.address-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .form-grid {
      grid-template-columns: 48% 48%;
      padding-bottom: 8px;
    }

    .location-section {
      width: 44vw;
    }

    .swap-address-btn {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .grid-item-corridor {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .grid-item-crossroad {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .grid-item-swap-address {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  /* For case when map presented in the creation stepper */
  .incident-section {
    .tablet-mode {
      &.address-form {
        display: block;
      }
    }

    .location-section {
      width: 100%;
    }
  }
}
