wc-slide-toggle {
  .mat-slide-toggle-content {
    margin-right: 6px;
    direction: ltr;
  }
}

.cdk-overlay-connected-position-bounding-box:has(.workspaces-container.desktop) {
  transform: translateX(-44px);
}
