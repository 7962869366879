/* @import url('https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.4.3/css/ol.css'); */
.ol-box {
  @include rounded-corner(2px);

  box-sizing: border-box;
  border: 2px solid #00f;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  @include rounded-corner(4px);

  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  background: rgba(0, 60, 136, 30%);
}

.ol-scale-line-inner {
  margin: 1px;
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  transition: all 0.25s;
  will-change: contents, width;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  z-index: 10;
  float: right;
  width: 1px;
  height: 15px;
  background-color: $black;
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  z-index: 11;
  color: var(--wc-black-to-white-alpha-7);
  font-size: 12px;
  text-shadow: -2px 0 $white, 0 2px $white, 2px 0 $white, 0 -2px $white;
}

.ol-scale-text {
  position: absolute;
  bottom: 25px;
  color: var(--wc-black-to-white-alpha-7);
  font-size: 14px;
  text-align: center;
  text-shadow: -2px 0 $white, 0 2px $white, 2px 0 $white, 0 -2px $white;
}

.ol-scale-singlebar {
  position: relative;
  z-index: 9;
  box-sizing: border-box;
  height: 10px;
  border: 1px solid $black;
}

.ol-unsupported {
  display: none;
}

.ol-unselectable,
.ol-viewport {
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.ol-selectable {
  user-select: text;
  user-select: text;
  user-select: text;
  user-select: text;
  -webkit-touch-callout: default;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  @include rounded-corner(4px);

  position: absolute;
  padding: 2px;
  background-color: unset;
}

.ol-control:hover {
  background-color: var(--wc-grey-2-to-dark-greyish-blue-3);
}

.ol-zoom {
  top: 0.5em;
  left: 0.5em;

  .ol-zoom-in {
    @include rounded-corner(2px 2px 0 0);
  }

  .ol-zoom-out {
    @include rounded-corner(0 0 2px 2px);
  }
}

.ol-rotate {
  top: 0.5em;
  right: 0.5em;
  transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: 0.5em;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.ol-control button {
  @include rounded-corner(2px);

  display: block;
  width: 1.375em;
  height: 1.375em;
  margin: 1px;
  padding: 0;
  border: none;
  background-color: var(--wc-white-to-dark-greyish-blue-9);
  color: var(--wc-greyish-blue-5-to-white-alpha-7);
  font-weight: 700;
  font-size: 19px;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
}

.ol-control button::-moz-focus-inner {
  padding: 0;
  border: none;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  will-change: transform;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-attribution {
  right: unset;
  bottom: 8px;
  left: 8px;
  display: flex;
  flex-direction: row-reverse;
  max-width: calc(100% - 1.3em);
  text-align: right;

  &:not(.ol-collapsed) {
    background-color: unset;
  }

  ul {
    margin: 0;
    padding: 0 0.5em;
    padding-top: 6px;
    color: var(--wc-black-to-white);
    opacity: 0.3;
  }

  img {
    vertical-align: middle;
    max-width: inherit;
    max-height: 2em;
  }

  button {
    display: flex;
    justify-self: center;
  }

  &.ol-collapsed ul {
    display: none;
  }

  &.ol-uncollapsible {
    @include rounded-corner(4px 0 0);

    right: unset;
    bottom: 0;

    img {
      max-height: 1.6em;
      margin-top: -0.2em;
    }

    button {
      display: none;
    }
  }

  li {
    display: inline;
    list-style: none;

    &:not(:last-child)::after {
      content: ' ';
    }
  }
}

.ol-zoomslider {
  top: 4.5em;
  left: 0.5em;
  height: 200px;

  button {
    position: relative;
    height: 10px;
  }
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: 0.5em;
  left: 0.5em;

  .ol-overviewmap-map {
    display: none;
    width: 150px;
    height: 150px;
    margin: 2px;
    border: 1px solid #7b98bc;

    button {
      display: inline-block;
    }
  }

  &.ol-uncollapsible {
    @include rounded-corner(0 4px 0 0);

    bottom: 0;
    left: 0;
  }

  &:not(.ol-collapsed) button {
    position: absolute;
    bottom: 1px;
    left: 2px;
  }

  .ol-uncollapsible button {
    display: none;
  }
}

.ol-overviewmap-box {
  border: 2px dotted rgba(0, 60, 136, 70%);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}
