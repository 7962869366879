/* stylelint-disable declaration-no-important */

/*
* Table Of Contents
* 1. HTML Element Resets
* 2. Material Element Resets
*   2.1. Basic Elements
*   2.2. Form Elements
*/

$custom-typography: mat-typography-config(
  $font-family: $primary-font-family,
);

* {
  box-sizing: border-box;
  outline: none;
}

@include mat-core($custom-typography);

.mat-typography {
  @include angular-material-typography($custom-typography);
}

/*******************
 Outline Styles
*******************/
*:focus,
*:focus-visible {
  outline: none !important;
}

a {
  &:focus-visible {
    @include focus-styles(0);
  }
}

button {
  &:focus-visible {
    @include focus-styles(-2px);
  }
}

mat-select:not(.outline-select) {
  &:focus-visible {
    @include focus-styles(-2px);
  }
}

.sort-select-dropdown {
  .mat-option {
    &.mat-active {
      @include focus-styles(-2px);
    }
  }
}

.multi-select-dropdown {
  .mat-option {
    &.mat-active {
      @include focus-styles(-2px);
    }
  }
}

mat-expansion-panel-header,
.mat-tab-label.mat-focus-indicator {
  &:focus-visible {
    @include focus-styles(-2px);
  }
}

// End focus styles

body {
  overflow-x: hidden;
  margin: 0;
  background: $app-loading-background;
  font-family: $primary-font-family;
}

html {
  font-family: $primary-font-family;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
}

h3 {
  font-size: 21px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

textarea::placeholder {
  color: var(--wc-greyscale-3-to-greyscale-7) !important;
}

input::placeholder {
  color: var(--wc-greyscale-3-to-greyscale-7) !important;
}
