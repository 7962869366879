.transit-autocomplete-select-panel.mat-select-panel {
  overflow-x: hidden;

  &.transit-autocomplete {
    width: 290px;
    min-width: initial !important;
    max-width: initial !important;
  }

  .mat-option {
    height: 36px;
    padding: 0 12px;
    padding-left: 36px;
    font-size: 14px;
    line-height: 36px;

    &.mat-selected.mat-active {
      background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
      color: $light-blue-8;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    padding: 0;
    border-top: 0;
  }

  // Multi-select styles
  .mat-pseudo-checkbox {
    width: 15px;
    height: 15px;
    margin-right: 12px;

    &.mat-pseudo-checkbox-checked {
      background-color: var(--wc-light-blue-6-to-light-blue-3-8);
    }

    &::after {
      top: 2px;
      left: 1px;
      width: 8px;
      height: 3px;
    }

    & + .mat-option-text {
      color: var(--wc-deep-blue-6-to-greyscale-3);
      font-family: $primary-font-family;
    }
  }

  // Static checkboxes
  .mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
    margin-right: 12px;

    &::after {
      border-width: 2px;
    }
  }

  .mat-checkbox-checked .mat-checkbox-checkmark-path {
    stroke-width: 3px;
  }
}
