@import url('https://fonts.googleapis.com/earlyaccess/opensanshebrew.css');
@import url('https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css');

@font-face {
  font-family: 'Nunito Regular';
  src: url('/assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito SemiBold';
  src: url('/assets/fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato Light';
  src: url('/assets/fonts/Lato-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Lato Thin';
  src: url('/assets/fonts/Lato-Thin.ttf') format('opentype');
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('/assets/fonts/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: url('/assets/fonts/Rubik-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Rubik Bold';
  src: url('/assets/fonts/Rubik-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Rubik Semi Bold';
  src: url('/assets/fonts/Rubik-SemiBold.ttf') format('opentype');
}

// Waycare Font Icons
@font-face {
  font-family: 'Waycare Icons';
  src: url('/assets/fonts/waycare-fonts/icomoon.eot') format('eot'); /* IE9 */
  src: url('/assets/fonts/waycare-fonts/icomoon.ttf') format('truetype'),
    url('/assets/fonts/waycare-fonts/icomoon.woff') format('woff'),
    url('/assets/fonts/waycare-fonts/icomoon.svg') format('svg');
}
