.view-traffic-disruption-tablet {
  overflow-y: auto;
  height: 100%;

  .mat-tab-group,
  .mat-tab-label-container {
    z-index: 0;
  }

  .mat-ink-bar {
    background-color: transparent !important;
  }

  .mat-tab-group {
    flex-direction: row;
    min-height: calc(100vh - #{$main-nav-header-height} - #{$view-incident-header-height});
  }

  .mat-tab-header {
    z-index: 1;
    width: 132px;
    border-bottom: none;
    background: var(--wc-side-panel-to-greyscale-8);
    box-shadow: 5px 0 10px rgba($deep-blue-6, 10%);
  }

  .mat-tab-list {
    width: 100%;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-labels {
    flex-direction: column;
  }

  .mat-ink-bar {
    left: 98% !important;
    height: 100%;
  }

  .mat-tab-body-wrapper {
    flex: 1 1 auto;
    background: var(--wc-side-panel-to-greyscale-8);
  }

  .mat-tab-group,
  .mat-tab-label-container {
    z-index: 0;
  }

  .mat-tab-labels {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .mat-tab-label {
    width: 100%;
    min-width: 132px;
    height: 80px;
    border-left: 5px solid transparent;
    color: $greyscale-5;
    font-size: 20px;
    font-family: $primary-font-family;
    text-align: left;

    .mat-tab-label-content {
      white-space: initial;
    }
  }

  .mat-tab-label-active {
    border-left: 5px solid $light-blue-8;
    background-color: rgba(0, 161, 218, 10%);
    color: $light-blue-8;
    font-weight: 600;
  }

  .mat-ink-bar {
    background-color: transparent !important;
  }

  .mat-tab-body-content {
    padding: 20px 20px 110px;
  }

  .unit-actions-panel {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 90px;
    background-color: var(--wc-white-to-dark-greyish-blue-9);
    box-shadow: 0 -5px 5px rgba($deep-blue-6, 20%);
    line-height: 90px;
    text-align: center;
  }

  .mat-button-toggle {
    width: 188px;
    font-size: 26px;
    font-family: $primary-font-family;
  }

  .mat-button-toggle-group {
    align-items: center;
  }

  .mat-button-toggle-group-appearance-standard {
    height: 100%;
    border: none;
  }

  .mat-button-toggle {
    height: 50px;

    &:first-child .mat-button-toggle-button {
      @include rounded-corner(4px 0 0 4px);

      border-right: none;
    }

    &:last-child .mat-button-toggle-button {
      @include rounded-corner(0 4px 4px 0);

      border-left: none;
    }
  }

  .mat-button-toggle-button {
    display: flex;
    justify-content: center;
    height: 100%;
    border: 1px solid var(--wc-deep-blue-6-to-greyscale-3);

    & + & {
      border-left: solid 1px $black-alpha-4 !important;
    }
  }

  .mat-button-toggle-checked {
    .mat-button-toggle-button {
      position: relative;
      border-color: $light-blue-8;
      background-color: $light-blue-3;
      color: $light-blue-8;
      font-weight: 700;

      &::before {
        content: '\f058';
        position: absolute;
        top: -19px;
        left: 10px;
        font-size: 20px;
        font-family: $fontawesome-icons;
      }
    }
  }

  .mat-button-toggle-label-content {
    display: flex;
    height: 48px;
  }
}
