wc-camera-gallery {
  wc-camera-thumbnail {
    .widget & {
      img {
        max-width: 100%;
        max-height: 53px;
      }
    }
  }
}
