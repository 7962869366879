@import '~ngx-toastr/toastr';

/// ngx-toastr basic overrides ///

.toast-container .ngx-toastr {
  .dark-theme & {
    box-shadow: none;
  }
}

.toast-success {
  background-color: $green-2;
  background-image: none;

  .toast-close-button {
    &:hover {
      background-color: $green-3;
    }
  }
}

.toast-error {
  background-color: $red-2;
  background-image: none;

  .toast-close-button {
    &:hover {
      background-color: $red-3;
    }
  }
}

.toast-container .ngx-toastr {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  width: fit-content;
  min-width: 350px;
  max-width: 450px;
  padding: 12px 16px;
  border-radius: 6px;
  color: $deep-blue-6;
  box-shadow: 0 5px 5px 0 rgba(15, 24, 50, 10%);
  font-family: $primary-font-family;
  column-gap: 15px;

  &:hover {
    box-shadow: 0 5px 5px 0 rgba(15, 24, 50, 15%);
  }

  a {
    padding: 0 10px;
    color: $deep-blue-6;
    font-family: $primary-font-family-bold;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}

.toast-message {
  flex: 1;
  line-height: 20px;
  text-align: center;
}

.toast-title {
  padding-bottom: 4px;
  font-family: $primary-font-family-bold;
}

.toast-close-button {
  position: unset;
  display: flex;
  grid-area: 1 / 2 / 3 / 3;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 28px;
  margin: -4px;
  border-radius: 4px;
  color: $deep-blue-6;
  font-weight: 100;
  font-size: 24px;
  text-shadow: none;

  &:hover {
    opacity: unset;
  }
}

///////////////////////////////

.toast-desktop-center-top {
  top: 71px;
  width: 100%;
  font-size: 14px;

  .ngx-toastr {
    margin-right: auto;
    margin-left: auto;
  }
}

.toast-desktop-bottom-left-panel {
  bottom: 80px;
  left: 90px;
  font-size: 14px;

  .ngx-toastr {
    width: 400px;
  }
}

.toast-desktop-bottom-right-panel {
  right: 65px;
  bottom: 80px;
  font-size: 14px;
}

.toast-desktop-auxiliary-panel {
  bottom: 95px;
  left: 662px;
  font-size: 14px;
}

.toast-widget-center-top {
  top: 60px;
  width: 100%;
  font-size: 14px;

  .ngx-toastr {
    margin-right: auto;
    margin-left: auto;
  }
}

.toast-tablet-center-top {
  top: 30px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;

  .ngx-toastr {
    width: fit-content;
    min-width: 450px;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 30px;
  }

  .toast-close-button {
    font-size: 30px;
  }

  .toast-message {
    line-height: 35px;
  }
}

.toast-tablet-center-bottom {
  bottom: 105px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;

  .ngx-toastr {
    width: fit-content;
    min-width: 450px;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 18px;
  }

  .toast-close-button {
    font-size: 30px;
  }

  .toast-message {
    line-height: 35px;
  }
}
