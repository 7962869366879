/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
 Topbar
*******************/
mat-toolbar {
  box-shadow: 1px 0 10px $black-alpha-5;

  &.topbar {
    padding: 0 20px;
  }

  .navbar-header {
    text-align: center;

    .navbar-brand {
      display: flex;
      align-items: center;
      line-height: 18px;
      all: unset;

      .logo {
        width: 30px;
        height: 36px;
        background: url('/assets/rekor-command-logo-tablet-light-mode.svg') no-repeat;

        .dark-theme & {
          background: url('/assets/rekor-command-logo-tablet-dark-mode.svg') no-repeat;
          background-size: cover;
        }
      }

      b {
        margin-right: 5px;
      }
    }
  }
}

/*******************
 Topbar
*******************/
.topbar {
  position: relative;

  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

// .sidenav-container {

/* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
//   flex: 1;
// }

.sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  // flex: 1 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header-toolbar {
  justify-content: space-between;

  &__release-note-badge {
    @include rounded-corner(50%);

    position: absolute;
    top: 2px;
    right: -15px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 20px;
    height: 20px;
    margin: -5px -5px 0 0;
    padding: 4px;
    background: $light-blue-7;
    color: var(--wc-white-to-black-alpha-7);
    font-size: 14px;
    font-family: $primary-font-family;
  }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: $main-nav-header-height !important;
  background-color: var(--wc-white-to-dark-greyish-blue-9) !important;
}

.mat-button {
  &.main-nav-link {
    min-width: 120px;
    height: 37px;
    padding: 0 24px;
    color: var(--wc-deep-blue-6-to-greyscale-3);
    font-size: 18px;
    font-family: $primary-font-family;
    line-height: 37px;
  }

  &:hover {
    color: $light-blue-8;
  }

  &.active {
    @include rounded-corner(100px);

    background: var(--wc-light-blue-0-to-light-blue-2-2);
    color: var(--wc-light-blue-6-to-light-blue-2);
    font-family: $primary-font-family-bold;
  }
}

.settings-menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .mat-button {
    padding: 0 8px;

    .menu-btn-title {
      display: inline-block;
      overflow: hidden;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.fa-user {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;

  .language-rtl & {
    margin-left: 6px;
  }
}

.main-nav-menu {
  &.mat-menu-panel {
    @include rounded-corner(3px);

    min-width: 184px;
    border: 1px solid var(--wc-disabled-to-white-alpha-2);
    box-shadow: 0 5px 5px rgba($deep-blue-6, 20%);
  }

  .mat-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    padding: 0 10px;
    font-family: $primary-font-family;
    line-height: 36px;

    &:hover:not([disabled]) {
      @include rounded-corner(4);

      background: $light-blue-2 !important;
      color: $light-blue-8;
      cursor: pointer;
    }
  }

  .mat-menu-content {
    &:not(:empty) {
      padding: 0;
    }
  }
}

.menu-toggle-button {
  display: flex;
  justify-content: space-between;
}

.toggle-button-content {
  display: flex;
  flex-direction: row;
}

.language-rtl {
  &.mat-menu-item {
    direction: rtl;
  }
}
